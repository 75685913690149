import * as React from 'react';
import { useHouseholds, useLayout } from './store';

export function useSetHousehold() {
  const { handleRequest, households = [] } = useHouseholds();
  const household = households[0];
  const [, setLayout] = useLayout();

  React.useEffect(() => {
    setLayout({ household: undefined });
    handleRequest();
  }, [setLayout, handleRequest]);

  const household_str = JSON.stringify(household);

  React.useEffect(() => {
    household !== undefined && setLayout({ household: JSON.parse(household_str) });
  }, [setLayout, household, household_str]);
}

export default function SetHouseholdGate({ children }) {
  useSetHousehold();
  return children;
}
