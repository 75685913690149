import * as React from 'react';
import { makeStyles, Grid, Paper, Typography, Divider, TextField, CircularProgress } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import BrandLogoImage from '../../images/full_logo.png';
import useRequest from '../axios/useRequest';
import { validateEmail, isEmpty } from '../../helpers';

const useStyles = makeStyles(({ palette, mixins }: any) =>
    createStyles({
        root: {
            background: palette.common.white,
            color: palette.primary.main,
            height: '100vh',
        },
        paper: {
            marginTop: 32,
            width: 300,
        },
        panelarea: {
            background: '#F5F5F5',
            padding: 16,
        },
        button: {
            marginTop: 8,
        },
        toolbar: mixins.toolbar,
        wrapper: {
            width: 224
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -8,
            marginLeft: -12,
        },
    })
);


const validate = (values) => {
    const newErrors = {};

    ['email'].forEach(k => {
        if (isEmpty(values[k])) {
            newErrors[k] = 'Required';
        }
    })

    if (!validateEmail(values['email'])) {
        newErrors['email'] = 'Invalid email format'
    }

    return newErrors;
}

export default function ForgotPassword({ onBack }) {
    const BannerMsg = "Don't fret.  Enter your email address below and we'll send you a link to create a new password for your account.";
    const classes = useStyles();
    const noAutoComplete = true;
    var buttonRef = React.useRef(null);

    const [values, setValues] = React.useState({
        email: '',
    });

    const { email } = values;

    const [{ disabled }, setState] = React.useState({ disabled: false });

    const [errors, setErrors] = React.useState({});
    const handleSuccess = React.useCallback(() => {
        alert('An email has been sent with a link to reset your password.')
        onBack();
    }, [onBack])

    const { handleRequest, error } = useRequest({ url: `/auth/reset?e=${email}&debug=true`, setState, onSuccess: handleSuccess, method: 'GET' });

    const handleSubmit = React.useCallback(() => {
        const newErrors = validate({ email });
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            handleRequest();
        }
    }, [handleRequest, email]);


    ///auth/reset?e=<email>&debug=<string></string>

    const handleChange = (name: string) => (event: any) => {
        setValues({ ...values, [name]: event.target.value })
        setErrors({});
    };

    // Per https://stackoverflow.com/questions/50604671/programmatically-disabling-chrome-auto-fill
    // The only way to prevent auto fill is autocomplete=newpassword
    // Note this only prevents the browser from auto populating the fields, it does not prevent chromes new password manager
    return (
        <div
            className={classes.root}
            onKeyDown={(e: any) => {
                if (e.keyCode === 13 && document.activeElement.id === "email") {
                    // Enter key
                    e.preventDefault()
                    handleSubmit()
                }
            }}
        >
            <div className={classes.toolbar} />
            <form autoComplete='off'>
                <Grid container direction='column' justify='center' alignItems='center'>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Typography align='center' className={classes.panelarea}>
                                <img src={BrandLogoImage} alt='logo' style={{ height: 44 }} />
                            </Typography>
                            <Divider />
                            <Grid container spacing={1} direction='column' justify='center' alignItems='center' style={{ padding: 16 }}>
                                <Grid item>
                                    <Typography color='secondary' align='center'>
                                        {BannerMsg}
                                    </Typography>
                                </Grid>
                                <Grid item >
                                    <TextField
                                        autoComplete={noAutoComplete ? 'new-password' : undefined}
                                        id='email'
                                        label='Email Address'
                                        disabled={disabled}
                                        value={email}
                                        onChange={handleChange('email')}
                                        margin='dense'
                                        variant='outlined'
                                        error={isEmpty(errors['email']) ? false : true}
                                        helperText={errors['email']}
                                        fullWidth
                                        autoFocus
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <div className={classes.wrapper}>
                                        <Button
                                            ref={buttonRef}
                                            fullWidth={true}
                                            disabled={disabled}
                                            color='primary'
                                            variant='contained'
                                            className={classes.button}
                                            onClick={handleSubmit}
                                        >
                                            Reset
                                        </Button>
                                        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className={classes.wrapper}>
                                        <Button
                                            ref={buttonRef}
                                            fullWidth={true}
                                            disabled={disabled}
                                            color='secondary'
                                            variant='contained'
                                            className={classes.button}
                                            onClick={onBack}
                                        >
                                            {'< Back'}
                                        </Button>
                                        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Typography color='error' align='center' className={classes.panelarea}>
                                {error && error}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}
