import * as React from 'react';
import { makeStyles, createStyles, Typography, Container, Paper, Grid } from '@material-ui/core';
import { useWidth } from '../layout/store';
import * as Icons from '@material-ui/icons';
import DialogButton from '../application/GenericDialog/DialogButton';
import * as TimeZoneDialog from '../application/GenericDialog/TimeZone/TimeZone';
import * as ProfileDialog from '../application/GenericDialog/Profile/Profile';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 300
    },
    paper: {
      padding: 8
    },
    productName: {
      fontWeight: 'bold',
      marginTop: 8
    },
    image: {
      width: 64,
      height: 64
    },
    actionButton: {
      width: 128,
      height: 128,
      color: 'white',
      background: '#489fdf',
      '&:hover': {
        color: 'white',
        background: '#489fdf'
      }
    }
  })
);

const Buttons = () => (
  <Grid container justify='center' spacing={4} style={{ marginTop: 16, marginBottom: 64 }}>
    {[
      { label: 'MY PROFILE', Icon: Icons.Person, Module: ProfileDialog },
      { label: 'TIMEZONE', Icon: Icons.SettingsBrightness, Module: TimeZoneDialog, disabled: true }
    ].map(({ label, Icon, Module = undefined, disabled }) => (
      <Grid key={label} item xs={6} style={{ textAlign: 'center' }}>
        <DialogButton Module={Module} variant='actionbutton' label={label} Icon={Icon} disabled={disabled} />
      </Grid>
    ))}
  </Grid>
);

export default function AccountSettings() {
  const classes = useStyles({});
  const width = useWidth();
  const Content = (
    <>
      <Typography variant='h6' color='primary' align='center'>
        ACCOUNT SETTINGS
      </Typography>
      <Buttons />
    </>
  );
  return (
    <Container maxWidth='sm' className={classes.root}>
      {width < 400 ? Content : <Paper className={classes.paper}>{Content}</Paper>}
    </Container>
  );
}
