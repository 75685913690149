import { createMuiTheme, Theme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

export interface ThemeLayout {
  leftDrawerWidth: number;
  rightDrawerWidth: number;
  toolbarheight: number;
  contentpadding: number;
  contentrowspacing: number;
}

const secondarylight = '#8E8E8E';
const secondarymain = '#616161';
const secondarydark = '#373737';
const contrast = '#FFFFFF';

const primarydark = '#f04e23';
const primarymain = '#f5845f';
const primarylight = '#f6a58a';

export const offlineOpacity = 0.4;

const basetheme = createMuiTheme({});
export const theme = createMuiTheme({
  palette: {
    primary: {
      light: primarylight,
      main: primarymain,
      dark: primarydark,
      contrastText: contrast,
    },
    secondary: {
      light: secondarylight,
      main: secondarymain,
      dark: secondarydark,
      contrastText: contrast,
    },
    action: {
      selected: basetheme.palette.primary.light,
    },
    error: red,
  },
  layout: {
    leftDrawerWidth: 220, //The width of the left nav drawer
    rightDrawerWidth: 260, //The width of the right nav drawer
    toolbarheight: 64, //The height of the top toolbar
    footerheight: 32, //The height of the bottom toolbar        
    tablefooterheight: 20, //The height of the table footer
    contentpadding: 8, //The padding around the inner layout content
    contentrowspacing: 2, //the spacing between each row of content (toolbar, filterbar, table, etc)    
    progressSize: 80, // size of the circular progress indicator shown when dialogs are submitting
  },
  overrides: {
    MuiTooltip: {
      // Name of the component ⚛️ / style sheet
      tooltip: {
        // Name of the rule
        maxWidth: 700,
      },
    },
    MuiTypography: {
      // Name of the component ⚛️ / style sheet
      h6: {
        lineHeight: 1.2,
      },
      subtitle1: {
        // Name of the rule
        lineHeight: 1.4,
        //background: basetheme.palette.background.default,
      },
    },
    MuiDialogTitle: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 12,
        paddingBottom: 12,
        //background: basetheme.palette.background.default,
      },
    },
    MuiDialogActions: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        background: basetheme.palette.grey[100],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: '0 0 auto',
        margin: 0,
        padding: '8px 4px',
        //padding: 0,
      },
    },
    MuiListItemIcon: {
      root: {
        color: basetheme.palette.grey[100],
      },
    },
  },
} as Theme & any);

export const MaxValues = {
  Int: 2147483647,
};

export const TermsAndConditions = `TERMS AND CONDITIONS 

Last updated: 1/27/2020

Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the http://www.skimdevil.com website and the Skimdevil mobile application (the "Service") operated by Skimdevil, LLC ("us", "we", or "our").

Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service. Please also read our Privacy Policy (linked). 

By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.

PURCHASES
If you wish to purchase any product or service made available through the Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, without limitation, your full name, address, …...

CONTENT
Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the …

LINKS TO OTHER WEB SITES
Our Service may contain links to third-party web sites or services that are not owned or controlled by Skimevil, LLC.

Skimdevil, LLC has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Skimdevil, LLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
 
CHANGES
We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 60 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
 
CONTACT US
If you have any questions about these Terms, please contact us at legal@skimdevil.com`
