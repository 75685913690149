import * as React from 'react';
import { makeStyles, createStyles, Toolbar, Link, AppBar, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(({ palette, zIndex }: any) =>
  createStyles({
    bottomAppBar: {
      top: 'auto',
      bottom: 0,
      zIndex: zIndex.drawer + 1,
    },
    bottomToolBar: {
      background: palette.common.white,
      color: palette.primary.main,
      minHeight: 32,
    },
  } as any)
);

export default function Footer() {
  const classes = useStyles();
  const BrandCopyright = "© 2020 SkimDevil";
  const BrandCopyrightURL = "https://skimdevil.com"  

  return (
    <AppBar position='fixed' color='primary' className={classes.bottomAppBar}>
      <Toolbar className={classes.bottomToolBar}>
        <Grid container justify='space-between'>
          <Grid item xs={10}>
            <Typography noWrap variant='body2'>
              <Link href={BrandCopyrightURL} target='_blank' variant='body2' color='inherit'>
                {BrandCopyright}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
