import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Button, IconButton, Grid, Typography, Fab, useTheme, ListItem, ListItemText, makeStyles, createStyles, Link, MenuItem } from '@material-ui/core';
import { useDialogState } from './actions';
import EditIcon from '@material-ui/icons/Edit';
import { evalFunc } from '../../../helpers';

export interface DialogModuleProps {
  default: any;
  title: string;
  props?: any;
}

export const renderDialogModule = ({ default: Dialog, title, ...props }) => Dialog && <Dialog id={title} {...props} />;

export const EditDialogButton = ({
  variant = 'iconbutton',
  placement = 'left',
  tooltip = 'Edit',
  mount = false, // Default to false, as most edit buttons are within the table rows, which get remounted in various scenaries (layout resizing etc)
  ...other
}) => <DialogButton variant={variant} Icon={EditIcon} type='Edit' tooltip={tooltip} placement={placement} mount={mount} {...other} />;

// Pass a dialog via Module.  If dialog is mounted elsewhere, set mounted = false to prevent duplicate mounts
export interface DialogButtonProps {
  id?: string;
  Module?: DialogModuleProps;
  type?: 'Add' | 'Edit' | 'View';
  tooltip?: string;
  placement?: any;
  color?: any;
  variant?: string;
  Icon?: any;
  initialValues?: object;
  children?: any;
}

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    primaryButton: {
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 7,
      color: palette.common.white,
      background: palette.primary.dark,
      '&:hover': {
        background: palette.primary.dark
      }
    },
    primaryButtonDisabled: {
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 7,
      color: `${palette.secondary.main} !important`,
      background: palette.primary.main,
      '&:hover': {
        background: palette.primary.dark
      }
    },
    image: {
      width: 64,
      height: 64
    },
    smallIconButton: {
      width: 20,
      height: 20,
      color: palette.secondary.main
    },
    actionButton: {
      width: 128,
      height: 128,
      color: 'white',
      background: '#489fdf',
      '&:hover': {
        color: 'white',
        background: '#489fdf'
      }
    },
    smallActionButton: ({ width = undefined }: any) => ({
      width,
      color: 'white',
      background: '#489fdf',
      '&:hover': {
        color: 'white',
        background: '#489fdf'
      }
    }),
    smallPrimaryActionButton: ({ width = undefined }: any) => ({
      width,
      color: 'white',
      background: palette.primary.dark,
      '&:hover': {
        color: 'white',
        opacity: 0.9,
        background: palette.primary.dark
      }
    }),
    outlinedPrimary: {
      color: 'white',
      border: `2px solid`,
      background: palette.primary.dark
    },
    listitem: {
      padding: 8,
      paddingLeft: 16,
      paddingRight: 8,
      background: 'inherit',
      '&:hover': {
        color: palette.common.white,
        background: palette.primary.main
      }
    },
    button: {},
    deleteButton: {
      color: palette.common.white,
      background: palette.error.main,
      '&:hover': {
        background: palette.error.dark
      }
    }
  })
);

const ActionButton = ({ Icon, label = 'Unknown', onClick, disabled }) => {
  const classes = useStyles({});
  return (
    <Button className={classes.actionButton} variant='outlined' onClick={onClick} disabled={disabled}>
      <Grid container>
        {Icon && (
          <Grid item xs={12}>
            <Icon className={classes.image} />
          </Grid>
        )}
        <Grid item xs={12}>
          {label}
        </Grid>
      </Grid>
    </Button>
  );
};

const SmallActionButton = ({ Icon, label = 'Unknown', onClick, disabled, width }) => {
  const classes = useStyles({ width });
  return (
    <Button className={classes.smallActionButton} variant='outlined' onClick={onClick} disabled={disabled}>
      {Icon && <Icon style={{ marginRight: 4 }} />}

      {label}
    </Button>
  );
};

const SmallPrimaryActionButton = ({ Icon, label = 'Unknown', onClick, disabled, width }) => {
  const classes = useStyles({ width });
  return (
    <Button className={classes.smallPrimaryActionButton} variant='outlined' onClick={onClick} disabled={disabled}>
      {Icon && <Icon style={{ marginRight: 4 }} />}

      {label}
    </Button>
  );
};

const OutlinedPrimary = ({ Icon, label = 'Unknown', onClick, disabled, size = 'small' as any }) => {
  const classes = useStyles({});
  return (
    <Button className={classes.outlinedPrimary} variant='outlined' size={size} onClick={onClick} disabled={disabled}>
      {Icon && <Icon style={{ marginRight: 4 }} />}
      {label}
    </Button>
  );
};

export default function DialogButton({
  id: Id = undefined,
  Module = undefined,
  type = 'Add',
  tooltip = undefined,
  placement = 'bottom',
  color = 'primary',
  variant = 'fab',
  size = 'small',
  label = '',
  disabled = undefined,
  Icon = AddIcon,
  initialValues = {},
  noGrid = true,
  mount = true,
  onClick = undefined,
  onClose = undefined,
  style = undefined,
  fullWidth = true,
  children,
  ...other
}: DialogButtonProps & any) {
  const classes = useStyles({});
  const theme = useTheme();
  const id = Id ? Id : Module && Module.title;
  const [, setDialogState] = useDialogState(id);

  const other_s = JSON.stringify(other);
  const handleUpdate = React.useCallback(() => {
    setDialogState({
      type,
      open: true,
      initialValues: evalFunc(initialValues),
      ...JSON.parse(other_s)
    });
  }, [setDialogState, other_s, initialValues, type]);

  const handleClose = React.useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const handleClick = React.useCallback(
    event => {
      event.stopPropagation();
      onClick && onClick(event);
      handleUpdate();
    },
    [onClick, handleUpdate]
  );

  const shared = {
    disabled,
    onClick: handleClick,
    size
  };

  const wrapGrid = Content =>
    !noGrid ? (
      <Grid key={tooltip} item>
        {Content}
      </Grid>
    ) : (
      Content
    );

  return wrapGrid(
    <>
      {mount && Module && renderDialogModule({ ...Module, onClose: handleClose })}
      <span>
        {variant === 'primaryButton' ? (
          <Button className={disabled ? classes.primaryButtonDisabled : classes.primaryButton} fullWidth={fullWidth} color={color as any} {...shared}>
            {children}
          </Button>
        ) : variant === 'menuitem' ? (
          <MenuItem {...shared}>{label}</MenuItem>
        ) : variant === 'actionbutton' ? (
          <ActionButton label={label} Icon={Icon} {...other} {...shared} />
        ) : variant === 'smallActionButton' ? (
          <SmallActionButton label={label} Icon={Icon} {...other} {...shared} />
        ) : variant === 'smallPrimaryActionButton' ? (
          <SmallPrimaryActionButton label={label} Icon={Icon} {...other} {...shared} />
        ) : variant === 'outlinedPrimary' ? (
          <OutlinedPrimary label={label} Icon={Icon} {...other} {...shared} />
        ) : variant === 'listitem' ? (
          <ListItem key={label} button={true} className={classes.listitem} {...shared}>
            <ListItemText primary={label} />
          </ListItem>
        ) : variant === 'iconbutton' ? (
          <IconButton color='inherit' {...shared}>
            {Icon && <Icon />}
          </IconButton>
        ) : variant === 'smalliconbutton' ? (
          <IconButton className={classes.smallIconButton} color='inherit' {...shared}>
            {Icon && <Icon className={classes.smallIconButton} />}
          </IconButton>
        ) : variant === 'text' ? (
          <Button
            color='secondary'
            style={{
              width: 48,
              height: 48
            }}
            variant={variant}
            {...shared}
          >
            {Icon && <Icon style={{ margin: -4 }} />}
          </Button>
        ) : variant === 'contained' ? (
          <Button
            className={style === 'delete' ? classes.deleteButton : classes.button}
            fullWidth={fullWidth}
            variant={variant}
            color={color as any}
            {...shared}
          >
            {children}
          </Button>
        ) : variant === 'link' ? (
          disabled ? (
            <Typography color='textSecondary'>{children}</Typography>
          ) : (
            <Link color={color} underline='always' style={{ cursor: 'pointer' }} {...shared}>
              {children}
            </Link>
          )
        ) : variant === 'linkcaption' ? (
          disabled ? (
            <Typography variant='caption' color='textSecondary'>
              {children}
            </Typography>
          ) : (
            <Link color={color} underline='always' style={{ cursor: 'pointer' }} {...shared}>
              <Typography variant='caption'>{children}</Typography>
            </Link>
          )
        ) : variant === 'outlined' ? (
          <Button variant='outlined' {...shared}>
            {children}
          </Button>
        ) : (
          <Fab
            size={size}
            variant='extended'
            color='primary'
            style={{
              color: disabled ? theme.palette.primary.light : theme.palette.common.white
            }}
            {...shared}
          >
            {Icon && <Icon style={{ margin: -4 }} />}
            {children && <div style={{ marginLeft: Icon ? 8 : 0 }}>{children}</div>}
          </Fab>
        )}
      </span>
    </>
  );
}
