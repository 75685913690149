import React from 'react';
import { useDialogState } from '../actions';
import GenericDialog from '../GenericDialog';
import Radio from '../../DialogField/Radio';
import { useLayout } from '../../../layout/store';

export const title = 'Temperature';

export default function TemperatureDialog({ id = title, onClose, ...other }) {
  const [, setDialogState] = useDialogState(id);
  const [{ tempUnit }, setLayout] = useLayout();

  const handleEdit = React.useCallback(
    ({ tempUnit }) => {
      setDialogState(prev => ({ ...prev, open: false, submitting: false, errors: {} }));
      setLayout({ tempUnit });
      onClose && onClose();
    },
    [setDialogState, onClose, setLayout]
  );

  const handleValidation = (values, dialogState) => {
    var errors = { ...dialogState.errors };
    return errors;
  };

  return (
    <GenericDialog
      id={id}
      title={title}
      onSubmit={handleEdit}
      onClose={onClose}
      validate={handleValidation}
      initialValues={{ tempUnit }}
      submitLabel='Save'
      fields={[
        {
          id: 'tempUnit',
          label: 'Temperature Preference',
          Field: Radio,
          variant: 'outlined',
          items: [
            { value: 'F', label: '° F' },
            { value: 'C', label: '° C' }
          ],
          initialValue: 'F'
        }
      ]}
      {...other}
    />
  );
}
