import * as React from 'react';
import { FormControl, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import { bool, isError } from '../../../helpers';

const Check = ({
  label = '',
  variant = undefined,
  error = undefined,
  value = undefined,
  margin = 'dense' as any,
  onChange = undefined,
  forceErrorMargin = false,
  fullWidth = true,
  type = undefined, // Don't pass an undefined type to Checkbox.  This filters the prop out
  getFieldState = undefined,
  width = undefined,
  ...other
}) => {
  const handleChange = React.useCallback(
    event => {
      onChange && onChange({ target: { value: event.target.checked === true ? 1 : 0 } }); // Inject checked value to mimic behavior of other input on change events
    },
    [onChange]
  );

  const Control = <Checkbox checked={bool(value)} onChange={handleChange} {...other} />;

  return (
    <FormControl
      variant={variant}
      error={isError(error)}
      fullWidth={fullWidth}
      margin={margin === 'denser' ? 'dense' : margin}
      style={{ width, margin: margin === 'denser' ? 0 : undefined }}
    >
      {label !== null ? <FormControlLabel label={label} control={Control} /> : Control}
      {(forceErrorMargin || error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default Check;
