import React from 'react';
import ReactDOM from 'react-dom';
import { InputLabel, makeStyles, createStyles } from '@material-ui/core';
import NotchedOutline from '@material-ui/core/OutlinedInput/NotchedOutline';

const useStyles = makeStyles(({ shape }: any) =>
  createStyles({
    root: {
      position: 'relative',
      marginTop: 8,
      marginBottom: 24,
      width: '100%'
    },
    container: {
      position: 'relative'
    },
    content: {
      padding: 16,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: shape.borderRadius
    },
    inputLabel: {
      position: 'absolute',
      left: 0,
      top: 0,
      // slight alteration to spec spacing to match visual spec result
      transform: 'translate(0, 24px) scale(1)'
    },
    action: {
      zIndex: 1,
      position: 'absolute',
      right: 8,
      top: 8
    }
  })
);

export default function OutlinedDiv({
  label = '',
  maxWidth = undefined,
  margin = undefined,
  padding = undefined,
  children = undefined,
  contentClass = undefined,
  action = undefined,
  style = {},
  contentStyle = {}
}) {
  const classes = useStyles({});
  const [labelWidth, setLabelWidth] = React.useState(0);
  const labelRef = React.useRef(null);
  React.useEffect(() => {
    const labelNode = ReactDOM.findDOMNode(labelRef.current);
    setLabelWidth(labelNode != null ? (labelNode as any).offsetWidth : 0);
  }, [label]);

  return (
    <div className={classes.root} style={{ maxWidth, margin, ...style }}>
      <InputLabel ref={labelRef} htmlFor={label} variant='outlined' className={classes.inputLabel} shrink>
        {label}
      </InputLabel>
      {action && <div className={classes.action}>{action}</div>}
      <div className={classes.container}>
        <div id={label} className={contentClass || classes.content} style={{ padding }}>
          <div style={contentStyle}>{children}</div>
          <NotchedOutline notched labelWidth={labelWidth} />
        </div>
      </div>
    </div>
  );
}
