import React from 'react';
import { useChannelIn, useChannelOut } from '../../../layout/store';

const defaultState = { progress: undefined, loading: false, rebooting: false };

export default function useBotFirmware({ BotId = undefined, open, setDialogState, onVersion = undefined }) {
  const [state, setState] = React.useState(defaultState); // For tracking firmware update progress.  Version is stored in the dialog state (not here)
  const progress = state?.progress;
  const onMessage = React.useCallback(
    packet => {
      var { message } = packet;
      const { type, data } = message;
      if (['telemetry', 'status'].includes(type)) {
        if (data?.version) {
          // Status messages store the firmware version in the version field, while telemetry stores in the fw_version field.
          console.log('Received bot version');          
          setDialogState(prev => (prev.open && prev.loading === true ? { ...prev, version: data?.version, loading: false } : prev));
          setState(defaultState);
          onVersion && onVersion(data?.version);
          return;
        }
        var progress = data?.update?.progress;
        const isUpdateStatus = progress !== undefined;
        setState(prev => {
          // Telemetry includes an update status (loading = true)
          // Telemetry does not include an update status
          // Scenario 1: We have started the update and received a non update status (loading = true, check progress = 0)
          // Scenario 2: We have not started an update and received a non update stats (loading = false)

          // We have started the update but receive a non update telemetry
          if (prev.progress === 0 && !isUpdateStatus) {
            console.log('Firmware update in progress...');
            return {
              ...prev,
              loading: true,
              progress: prev.progress,
              rebooting: false
            };
          } else if (isUpdateStatus) {
            console.log(`Firmware update progress: ${progress}`);
            return {
              ...prev,
              loading: progress === 100 ? false : true, // The status is loading unless 100% has been reached
              rebooting: progress === 100 ? true : false,
              progress // update the associated progress value
            };
          } else if (!isUpdateStatus) {
            return {
              ...prev,
              loading: false, // If previous progress is not 0 and we have received a non update status telemetry, then we are not loading
              progress: undefined, // reset the associated progress value
              rebooting: false
            };
          }
        });
      }
    },
    [onVersion, setDialogState, setState]
  );

  const { subscribed: inSubscribed, handlePublish } = useChannelIn({ BotId, disabled: !open });
  const { subscribed: outSubscribed } = useChannelOut({ BotId, onMessage, disabled: !open });

  const handleRequestConfig = React.useCallback(() => {
    console.log(`Requesting bot version`);
    setDialogState(prev => ({ ...prev, loading: true }));
    handlePublish(
      JSON.stringify(
        {
          type: 'get_version'
        },
        null,
        0
      )
    );
  }, [setDialogState, handlePublish]);

  // Automatically request the bot version on open trigger
  React.useEffect(() => {
    open && inSubscribed && outSubscribed && (progress === undefined || progress === 100) && handleRequestConfig();
  }, [progress, open, inSubscribed, outSubscribed, handleRequestConfig]);

  const handleFirmwareUpdate = React.useCallback(() => {
    console.log(`Updating bot firmware`);
    setState(prev => ({ ...prev, loading: true, progress: 0 })); // Be sure to set progress to 0, so we know we have started
    handlePublish(
      JSON.stringify(
        {
          type: 'update_fw',
          msg: {
            doupdate: true
            // force: true // for testing purposes
          }
        },
        null,
        0
      )
    );
  }, [setState, handlePublish]);

  return {
    subscribed: inSubscribed && outSubscribed,
    handleFirmwareUpdate,
    progress: state?.progress,
    loading: state?.loading,
    rebooting: state?.rebooting
  };
}
