import * as React from 'react';
import { Slider as MuiSlider, Typography, Box } from '@material-ui/core';

export default function Slider({ value = 0, defaultValue = 0, forceErrorMargin = false, error = undefined, getFieldState = undefined, label = '', onChange = undefined, ...other }) {
    const handleChange = React.useCallback((event, value) => {
        onChange && onChange({ target: { value } })
    }, [onChange])

    return (
        <>
            <Typography>
                {label}
            </Typography>
            <Box m={2}>
                <MuiSlider
                    onChange={handleChange}
                    defaultValue={defaultValue}
                    value={value}
                    {...other}
                />
            </Box>
        </>

    );
}
