import React from 'react';
import './App.css';
import AppRouter from './components/layout/AppRouter';
import { AppWrapper, history } from './AppShared';
import Routes from './components/layout/routes';
import Layout from './components/layout/Layout';
import SetHouseholdGate from './components/layout/SetHouseHoldGate';

/**
 *The root application component
 * @function App
 */

function App() {
  return (
    <AppWrapper>
      <React.Suspense fallback={<></>}>
        <AppRouter history={history}>
          <SetHouseholdGate>
            <Layout>
              <Routes />
            </Layout>
          </SetHouseholdGate>
        </AppRouter>
      </React.Suspense>
    </AppWrapper>
  );
}

export default App;
