import React from 'react';
import { versionUrl } from '../../../../../package.json';
import axios from '../../../axios/config';

export default function useVersionInfo({ trigger }) {
  const [state, setState] = React.useState({});
  React.useEffect(() => {
    const getUrl = async () => {
      const method = 'get';
      const url = versionUrl;
      const result = await axios({ method, url });
      if (result.status === 200) {
        setState(result.data);
      } else {
        console.error('Error retreiving version information');
        console.error(result);
      }
    };
    trigger && getUrl();
  }, [trigger]);

  return state;
}
