import React from 'react';
import { useDialogState } from '../actions';
import GenericDialog from '../GenericDialog';
import { useProfileData } from '../../../layout/store';

export const title = 'Profile';

export default function ProfileDialog({ id = title, onClose, ...other }) {
    const [state, setState] = useDialogState(id);
    const { open } = state;

    const { handleRequest, handleUpdate, /*handleDelete,*/ profile } = useProfileData({ setState });

    React.useEffect(() => {
        open && handleRequest({});
    }, [open, handleRequest])

    //const handleLogout = LayoutStore.useLogout();

    const handleSubmit = React.useCallback(
        values => {
            const onSuccess = (response) => {
                setState(prev => ({ ...prev, open: false }))
                onClose && onClose(response)
            }
            handleUpdate(values, onSuccess);
        },
        [handleUpdate, setState, onClose]
    );

    /*const handleSubmitDelete = React.useCallback(
        values => {
            const onSuccess = (response) => {
                setState(prev => ({ ...prev, open: false }))
                onClose && onClose(response)
                handleLogout();
            }
            handleDelete(values, onSuccess);
        },
        [handleDelete, handleLogout, setState, onClose]
    );*/

    const handleValidation = (values, dialogState) => {
        var errors = { ...dialogState.errors };
        return errors;
    };

    return (
        <GenericDialog
            id={id}
            type='Edit'
            title={title}
            onSubmit={handleSubmit}
            //onDelete={handleSubmitDelete}
            onClose={onClose}
            validate={handleValidation}
            initialValues={profile}
            submitLabel='Save'
            deleteLabel='Delete Account'
            fields={[
                {
                    id: 'id',
                    hidden: true,
                },
                {
                    id: 'name',
                    label: 'Name',
                    required: true,
                },
                {
                    id: 'email',
                    label: 'Email',
                    required: true,
                },
                {
                    object: 'address',
                    id: 'address1',
                    label: 'Street Address 1',
                },
                {
                    object: 'address',
                    id: 'address2',
                    label: 'Street Address 2',
                },
                {
                    object: 'address',
                    id: 'city',
                    label: 'City',
                },
                {
                    object: 'address',
                    id: 'state',
                    label: 'State',
                    xs: 4,
                },
                {
                    object: 'address',
                    id: 'postal_code',
                    label: 'Zip Code',
                    xs: 8,
                },
                {
                    object: 'address',
                    id: 'country',
                    label: 'Country',
                },
                {
                    id: 'phone',
                    label: 'Phone Number',
                },
            ]}
            {...other}
        />
    );
}
