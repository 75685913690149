import * as React from 'react';
import * as LayoutStore from './store';
import { useHistory } from 'react-router-dom';

const Logout = () => {
  const history = useHistory();
  const logout = LayoutStore.useLogout();
  React.useCallback(() => {
    console.log('Logging Out');
    logout();
    history && history.replace('/');
  }, [logout, history]);
  return <></>;
};

export default Logout;
