import * as React from 'react';
import useComponentSize from '@rehooks/component-size';
import { useHistory, useLocation } from 'react-router';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useResizeAppBar, useRouteState } from './components/layout/store';

export const useAppBarHeightRef = () => {
  let ref = React.useRef(null);
  const { height } = useComponentSize(ref);
  const resizeAppBar = useResizeAppBar();
  React.useEffect(() => {
    resizeAppBar(height);
  }, [resizeAppBar, height]);
  return ref;
};

export const useHandleChangeRoute = () => {
  const changeRoute = useChangeRoute();
  return React.useCallback((route, state = undefined) => event => changeRoute(route, state), [changeRoute]);
};

export const useChangeRoute = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [, setState] = useRouteState();
  return React.useCallback(
    (route: string, state = undefined) => {
      pathname !== route && history && history.push(route);
      if (state !== undefined) setState(state);
    },
    [history, pathname, setState]
  );
};

export const useFullScreen = (bp = 'sm') => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(bp as any));
};

export const useSmallScreen = (bp = 'sm') => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(bp as any));
};

export const useUrlParameter = paramName => {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === paramName) {
      return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
  return undefined;
};
