import * as React from 'react';
import { makeStyles, createStyles, Typography, Grid } from '@material-ui/core';
import { EditDialogButton } from '../../application/GenericDialog/DialogButton';

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      width: 64,
      height: 64
    }
  })
);

export default function Vital({ image, status, name, editModule = undefined }) {
  const classes = useStyles({});
  return (
    <Grid container justify='center' spacing={1}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <img src={image} alt={status} className={classes.image} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle2' color='primary' align='center'>
          {status} {editModule && <EditDialogButton Module={editModule} mount={true} variant='smalliconbutton' />}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle2' color='secondary' align='center'>
          {name}
        </Typography>
      </Grid>
    </Grid>
  );
}
