import * as React from 'react';
import { Grid } from '@material-ui/core';
import OutlinedDiv from '../../general/OutlinedDiv/OutlinedDiv';
import { useLayout } from '../../layout/store';
import Solar from '../../../images/solar-light.svg';
import PoolTemp from '../../../images/pool-temp-light.svg';
import * as TemperatureDialog from '../../application/GenericDialog/Temperature/Temperature';
import { getBatteryLevel, getStatusText, getTemperatureText, getWatts } from './helpers';
import Vital from './Vital';
import { offlineOpacity } from '../../../constants';

export default function Vitals({ telemData }) {
  const { telem = {}, offline } = telemData;

  const [{ tempUnit }] = useLayout();
  const { temp, solar_p, schedule = {} } = telem;
  const [battIcon, battText] = getBatteryLevel(telem);
  const { offlineDurationStr = '' } = getStatusText(offline, telem, schedule);
  const offlineTimeCaptionStr = offline ? `(as of ${offlineDurationStr} ago)` : '';

  return (
    <OutlinedDiv label={`VITALS ${offlineTimeCaptionStr}`} contentStyle={{ opacity: offline ? offlineOpacity : 1 }}>
      <Grid container justify='center' alignItems='center' spacing={1} style={{ marginTop: 8, marginBottom: 8 }}>
        <Grid item xs={4}>
          <Vital image={battIcon} status={battText} name='BATTERY STATUS' />
        </Grid>
        <Grid item xs={4}>
          <Vital image={Solar} status={getWatts(solar_p)} name='SOLAR POWER' />
        </Grid>
        <Grid item xs={4}>
          <Vital image={PoolTemp} status={getTemperatureText({ temp, tempUnit })} name='POOL TEMP' editModule={TemperatureDialog} />
        </Grid>
      </Grid>
    </OutlinedDiv>
  );
}
