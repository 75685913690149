import * as React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio as MuiRadio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { isError } from '../../../helpers';
import OutlinedDiv from '../../general/OutlinedDiv/OutlinedDiv';

const RadioStandard = ({
  items,
  label,
  variant,
  error,
  margin = 'dense' as 'dense',
  onChange,
  disabled,
  forceErrorMargin,
  getFieldState = undefined,
  ...other
}) => (
  <FormControl
    component='fieldset'
    variant={variant}
    error={isError(error)}
    fullWidth
    margin={margin}
    disabled={disabled}
  >
    <Typography color='textSecondary'>{label}</Typography>
    <RadioGroup onChange={onChange} {...other}>
      {items.map((i) => (
        <FormControlLabel key={i.value} value={i.value} control={<MuiRadio />} label={i.label} />
      ))}
    </RadioGroup>
    {(forceErrorMargin || error) && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

const Radio = ({ label, variant, action = undefined, placement = undefined, ...other }: any) =>
  variant === 'outlined' ? (
    <OutlinedDiv label={label} action={placement === undefined && action}>
      <RadioStandard variant={variant} label={null} {...other} />
      {placement === 'end' && action}
    </OutlinedDiv>
  ) : (
    <RadioStandard label={label} variant={variant} {...other} />
  );

export default Radio;
