import React from 'react';
import { useDialogState } from '../actions';
import GenericDialog from '../GenericDialog';
import Select from '../../DialogField/Select';
import timezones from '../../../../timezones';

export const title = 'Time Zone';

export default function TimeZoneDialog({ id = title, onClose, ...other }) {
  const [, setDialogState] = useDialogState(id);

  const handleEdit = React.useCallback(
    values => {
      setDialogState(prev => ({ ...prev, open: false, submitting: false, errors: {} }));
      onClose && onClose();
    },
    [setDialogState, onClose]
  );

  const handleValidation = (values, dialogState) => {
    var errors = { ...dialogState.errors };
    return errors;
  };

  return (
    <GenericDialog
      id={id}
      title={title}
      onSubmit={handleEdit}
      onClose={onClose}
      validate={handleValidation}
      initialValues={{}}
      submitLabel='Save'
      fields={[
        {
          id: 'timezoneminutes',
          label: 'Your Time Zone',
          Field: Select,
          items: timezones
        }
      ]}
      {...other}
    />
  );
}
