import React from 'react';
import { useDialogState } from '../actions';
import GenericDialog from '../GenericDialog';
import PoolEdgeSensor from '../../DialogField/PoolEdgeSensor';

export const title = 'Pool Edge Sensor';

export default function PoolEdgeSensorDialog({ id = title, onClose, ...other }) {
    const [, setDialogState] = useDialogState(id);

    const handleEdit = React.useCallback(
        values => {
            setDialogState(prev => ({ ...prev, open: false, submitting: false, errors: {} }));
            onClose && onClose();
        },
        [setDialogState, onClose]
    );

    const handleValidation = (values, dialogState) => {
        var errors = { ...dialogState.errors };
        return errors;
    };

    return (
        <GenericDialog
            id={id}
            title={title}
            onSubmit={handleEdit}
            onClose={onClose}
            validate={handleValidation}
            initialValues={{}}
            submitLabel='Save'
            fields={[
                {
                    id: 'PoolEdgeSensor',
                    label: 'This setting will adjust how close you want your Skimdevil to get to the sides of your pool before turning.  Keeping the setting on DEFAULT will work for most pools, but you can change it for your specific pool.',
                    Field: PoolEdgeSensor
                },
            ]}
            {...other}
        />
    );
}
