export const BUILD_TARGETS = [
  {
    name: 'client'
  },
  {
    name: 'admin'
  }
];

const buildTarget = BUILD_TARGETS.find(({ name }) => process.env.REACT_APP_BUILD_TARGET === name) ?? BUILD_TARGETS[0];
export const isAdmin = buildTarget.name === 'admin';

export default buildTarget;
