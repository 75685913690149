import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, createStyles, useTheme } from '@material-ui/core';
import ApplicationBar from './ApplicationBar';
import Footer from './Footer';
import LeftDrawer from './LeftDrawer';
import SnackBar from '../application/SnackBar/SnackBar';
import { useAppBarHeight, useHeight } from './store';

const useStyles = makeStyles(({ breakpoints, mixins, layout }: any) =>
  createStyles({
    root: {
      display: 'static'
    },
    content: {
      flexGrow: 1,
      marginLeft: layout.leftDrawerWidth,
      [breakpoints.down('sm')]: {
        marginLeft: 0,
        flexShrink: 0
      }
    },
    toolbar: mixins.toolbar,
    innerContent: {
      padding: layout.contentpadding,
      flex: '1 1 auto',
      WebkitOverflowScrolling: 'touch', // Add iOS momentum scrolling.
      overflowY: 'auto',
      overflowX: 'hidden'
    }
  })
);

export const noPadPaths = [];

export default function Layout({ children }) {
  const classes = useStyles({});
  const theme = useTheme();
  const { pathname } = useLocation();
  const height = useHeight();
  const appBarHeight = useAppBarHeight();
  const footerHeight = (theme as any).layout.footerheight;
  const padding = (theme as any).layout.contentpadding * 2;
  const icHeight = height - appBarHeight - footerHeight - padding;
  return pathname === '/Logout' ? (
    children
  ) : (
    <div data-testid='app-container' className={classes.root}>
      <main className={classes.content}>
        <ApplicationBar auth={true} />
        <LeftDrawer />
        <div className={classes.toolbar} />
        <div className={classes.innerContent} style={{ height: icHeight }}>
          {children}
        </div>
        <Footer />
        <SnackBar />
      </main>
    </div>
  );
}
