import React from 'react';
import { useDialogState } from '../actions';
import GenericDialog from '../GenericDialog';
import Radio from '../../DialogField/Radio';

export const title = 'LED Lights';

export default function LedLightsDialog({ id = title, onClose, ...other }) {
    const [, setDialogState] = useDialogState(id);

    const handleEdit = React.useCallback(
        values => {
            setDialogState(prev => ({ ...prev, open: false, submitting: false, errors: {} }));
            onClose && onClose();
        },
        [setDialogState, onClose]
    );

    const handleValidation = (values, dialogState) => {
        var errors = { ...dialogState.errors };
        return errors;
    };

    return (
        <GenericDialog
            id={id}
            title={title}
            onSubmit={handleEdit}
            onClose={onClose}
            validate={handleValidation}
            initialValues={{}}
            submitLabel='Save'
            fields={[
                {
                    id: 'basket',
                    label: 'Basket',
                    Field: Radio,
                    variant: 'outlined',
                    items: [
                        { value: 'ON', label: 'ON' },
                        { value: 'OFF', label: 'OFF' },
                    ],
                    initialValue: 'ON',
                    required: true,
                },
                {
                    id: 'front',
                    label: 'Front',
                    Field: Radio,
                    variant: 'outlined',
                    items: [
                        { value: 'ON', label: 'ON' },
                        { value: 'OFF', label: 'OFF' },
                        { value: 'AUTO', label: 'AUTO' },
                    ],
                    initialValue: 'ON',
                    required: true,
                },
            ]}
            {...other}
        />
    );
}
