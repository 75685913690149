import * as React from 'react';
import Slider from './Slider';

const marks = [    
    {
        value: 20,
        label: '20',
    },
    {
        value: 40,
        label: '40',
    },
    {
        value: 60,
        label: '60',
    },
];

const RobotMinutes = ({ getFieldState = undefined, ...props }) => <Slider marks={marks} step={20} {...props} min={20} max={60} />;
export default RobotMinutes