import React from 'react';
import { useDialogState } from '../actions';
import GenericDialog from '../GenericDialog';
import Radio from '../../DialogField/Radio';
import Switch from '../../DialogField/Switch';
import RobotMinutes from '../../DialogField/RobotMinutes';
import { utcTimeToLocal, checkEmpty } from '../../../../helpers';
import UtcTime from '../../DialogField/UtcTime';
import Divider from '../../DialogField/Divider';
import { useChannelIn, useChannelOut } from '../../../layout/store';
import { mapValuesIn, mapValuesOut } from './helpers';

export const title = 'Cleaning Schedule';
const min = 0;
const max = 2359;

export default function CleaningScheduleDialog({ id = title, onClose, ...other }) {
  const [{ open, initialData = undefined, initialValues }, setDialogState] = useDialogState(id);

  const handleClose = React.useCallback(() => {
    setDialogState(prev => ({ ...prev, initialData: undefined, initialValues: undefined, loading: false }));
    onClose && onClose();
  }, [onClose, setDialogState]);

  const onMessage = React.useCallback(
    packet => {
      var { message } = packet;
      const { type, data } = message;
      if (type === 'config' && initialData === undefined) {
        console.log('Received bot config');
        const initialValues = mapValuesIn(data); // map config structure to local data state
        console.log({ message, data, initialValues });
        // Store both data, which is the original config structure as well as the local state initialValues
        setDialogState(prev => (prev.open && prev.loading === true ? { ...prev, initialData: data, initialValues, loading: false } : prev));
      }
    },
    [initialData, setDialogState]
  );

  const { subscribed: inSubscribed, handlePublish } = useChannelIn({ disabled: !open });
  const { subscribed: outSubscribed } = useChannelOut({ onMessage, disabled: !open });

  const handleRequestConfig = React.useCallback(() => {
    console.log(`Requesting bot config`);
    setDialogState(prev => ({ ...prev, loading: true }));
    handlePublish(
      JSON.stringify(
        {
          type: 'send_config',
          filename: null
        },
        null,
        0
      )
    );
  }, [setDialogState, handlePublish]);

  React.useEffect(() => {
    open && inSubscribed && outSubscribed && handleRequestConfig();
  }, [open, inSubscribed, outSubscribed, handleRequestConfig]);

  //console.log({ requesting, initialValues, initialValuesNext, status, subscriptionStatus, lastMessageOnTopic });

  const handleSubmit = React.useCallback(
    values => {
      setDialogState(prev => ({ ...prev, open: false, submitting: false, errors: {} }));
      console.log({
        originalData: initialData,
        newData: mapValuesOut(initialData, values),
        newConfigObject: {
          type: 'config_update',
          filename: 'config.json',
          data: mapValuesOut(initialData, values)
        }
      });

      handlePublish(
        JSON.stringify(
          {
            type: 'config_update',
            filename: 'config.json',
            data: mapValuesOut(initialData, values)
          },
          null,
          0
        )
      );
      handleClose();
    },
    [initialData, handlePublish, setDialogState, handleClose]
  );

  const handleValidation = (values, dialogState) => {
    var errors = { ...dialogState.errors };
    const newErrors = {};

    // Values are in utcTime when they get to validation
    const start1 = checkEmpty(values.start1) ? values.start1 : utcTimeToLocal(values.start1);
    const end1 = checkEmpty(values.end1) ? values.end1 : utcTimeToLocal(values.end1);
    const start2 = checkEmpty(values.start2) ? values.start2 : utcTimeToLocal(values.start2);
    const end2 = checkEmpty(values.end2) ? values.end2 : utcTimeToLocal(values.end2);

    if (values.enabled1 === true) {
      if (checkEmpty(start1) || Number(start1) < min || Number(start1) > max || Number(start1) >= Number(end1)) {
        newErrors['start1'] = 'Invalid time range';
      }
      if (checkEmpty(end1) || Number(end1) < min || Number(start1) > max || Number(end1) <= Number(start1)) {
        newErrors['end1'] = 'Invalid time range';
      }
    }

    if (values.enabled2 === true) {
      if (checkEmpty(start2) || Number(start2) < min || Number(start2) > max || Number(start2) >= Number(end2)) {
        newErrors['start2'] = 'Invalid time range';
      }
      if (checkEmpty(end2) || Number(end2) < min || Number(start2) > 2359 || Number(end2) <= Number(start2)) {
        newErrors['end2'] = 'Invalid time range';
      }
    }
    return { ...errors, ...newErrors };
  };

  return (
    <GenericDialog
      id={id}
      title={title}
      onSubmit={handleSubmit}
      onClose={handleClose}
      validate={handleValidation}
      initialValues={initialValues}
      submitLabel='Save'
      fields={[
        {
          id: 'enabled1',
          label: 'Cleaning 1',
          Field: Switch
        },
        {
          id: 'start1',
          label: 'Start Time',
          Field: UtcTime,
          active: values => values.enabled1 === true,
          xs: 6
        },
        {
          id: 'end1',
          label: 'End Time',
          Field: UtcTime,
          active: values => values.enabled1 === true,
          xs: 6
        },
        {
          Field: Divider
        },
        {
          id: 'enabled2',
          label: 'Cleaning 2',
          Field: Switch
        },
        {
          id: 'start2',
          label: 'Start Time',
          Field: UtcTime,
          active: values => values.enabled2 === true,
          xs: 6
        },
        {
          id: 'end2',
          label: 'End Time',
          Field: UtcTime,
          active: values => values.enabled2 === true,
          xs: 6
        },
        {
          Field: Divider
        },
        {
          id: 'frequency',
          label: 'Choose how often you want your Skimdevil to clean',
          Field: Radio,
          variant: 'outlined',
          items: [
            { value: 'ECO', label: 'ECO - 20 min active / 40 min paused' },
            { value: 'REGULAR', label: 'REGULAR - 40 min active / 40 min paused' },
            { value: 'HEAVY', label: 'HEAVY - 40 min active / 20 min paused' },
            { value: 'CUSTOM', label: 'CUSTOM' }
          ],
          initialValue: 'ECO',
          active: values => values.enabled1 === true || values.enabled2 === true
        },
        {
          id: 'active_duration_mins',
          label: 'Custom Robot Active Minutes',
          Field: RobotMinutes,
          active: values => values.frequency === 'CUSTOM' && (values.enabled1 === true || values.enabled2 === true)
        },
        {
          id: 'paused_duration_mins',
          label: 'Custom Robot Paused Minutes',
          Field: RobotMinutes,
          active: values => values.frequency === 'CUSTOM' && (values.enabled1 === true || values.enabled2 === true)
        }
      ]}
      {...other}
    />
  );
}
