import * as React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import AuthGate from './AuthGate';
import { Connector } from '../mqtt';
import { brokerUrl } from '../../../package.json';

export interface AppRouterProps {
  auth?: boolean;
  history?: any;
  children?: any;
}

function AppRouter(props: AppRouterProps) {
  const { auth = true, history, children } = props;
  const AuthComponent = auth ? AuthGate : React.Fragment;
  return (
    <ConnectedRouter history={history}>
      <AuthComponent>
        <Connector brokerUrl={brokerUrl}>{children}</Connector>
      </AuthComponent>
    </ConnectedRouter>
  );
}

const mapStateToProps = (state: AppState, ownProp: AppRouterProps): AppRouterProps => {
  var history = ownProp.history;
  const location = state && state.router && state.router.location; //location from redux store (previously persisted location)
  history.location = location && location.pathname !== '/Logout' ? location : history.location; //if a previous location exists, then set it, otherwise use the passed in/default value
  return { history };
};

export default connect(mapStateToProps)(AppRouter);
