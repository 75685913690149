import React from 'react';
import { useDialogState } from '../actions';
import GenericDialog from '../GenericDialog';
import Speed from '../../DialogField/Speed';

export const title = 'Speed';

export default function SpeedDialog({ id = title, onClose, ...other }) {
    const [, setDialogState] = useDialogState(id);

    const handleEdit = React.useCallback(
        values => {
            setDialogState(prev => ({ ...prev, open: false, submitting: false, errors: {} }));
            onClose && onClose();
        },
        [setDialogState, onClose]
    );

    const handleValidation = (values, dialogState) => {
        var errors = { ...dialogState.errors };
        return errors;
    };

    return (
        <GenericDialog
            id={id}
            title={title}
            onSubmit={handleEdit}
            onClose={onClose}
            validate={handleValidation}
            initialValues={{}}
            submitLabel='Save'
            fields={[
                {
                    id: 'Speed',
                    label: 'Select desired speed',
                    Field: Speed
                },
            ]}
            {...other}
        />
    );
}
