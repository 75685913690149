import * as React from 'react';
import { makeStyles, createStyles, Typography, Container, Paper, Grid } from '@material-ui/core';
import { useWidth } from '../layout/store';
import * as Icons from '@material-ui/icons';
import DialogButton from '../application/GenericDialog/DialogButton';
import * as SpeedDialog from '../application/GenericDialog/Speed/Speed';
import * as PoolEdgeSensorDialog from '../application/GenericDialog/PoolEdgeSensor/PoolEdgeSensor';
import * as LedLightsDialog from '../application/GenericDialog/LedLights/LedLights';
import * as CleaningScheduleDialog from '../application/GenericDialog/CleaningSchedule/CleaningSchedule';
import * as FirmwareVersionDialog from '../application/GenericDialog/FirmwareVersion/FirmwareVersion';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 300
    },
    paper: {
      padding: 8
    },
    productName: {
      fontWeight: 'bold',
      marginTop: 8
    },
    image: {
      width: 64,
      height: 64
    },
    actionButton: {
      width: 128,
      height: 128,
      color: 'white',
      background: '#489fdf',
      '&:hover': {
        color: 'white',
        background: '#489fdf'
      }
    }
  })
);

export const DeviceSettingsModules = [
  { label: 'CLEANING SCHEDULE', Icon: Icons.Schedule, Module: CleaningScheduleDialog },
  { label: 'FIRMWARE VERSION', Icon: Icons.SystemUpdateAlt, Module: FirmwareVersionDialog },
  { label: 'SPEED', Icon: Icons.Speed, Module: SpeedDialog, disabled: true },
  { label: 'POOL EDGE SENSOR', Icon: Icons.BorderStyle, Module: PoolEdgeSensorDialog, disabled: true },
  { label: 'LED LIGHTS', Icon: Icons.Flare, Module: LedLightsDialog, disabled: true }
];

const Buttons = () => (
  <Grid container justify='center' spacing={4} style={{ marginTop: 16, marginBottom: 64 }}>
    {DeviceSettingsModules.map(({ label, Icon, Module = undefined, disabled }) => (
      <Grid key={label} item xs={6} style={{ textAlign: 'center' }}>
        <DialogButton Module={Module} variant='actionbutton' label={label} Icon={Icon} disabled={disabled} />
      </Grid>
    ))}
  </Grid>
);

const Content = () => (
  <>
    <Typography variant='h6' color='primary' align='center'>
      DEVICE SETTINGS
    </Typography>
    <Buttons />
  </>
);

export default function DeviceSettings() {
  const classes = useStyles({});
  const width = useWidth();

  return (
    <Container maxWidth='sm' className={classes.root}>
      {width < 400 ? (
        <Content />
      ) : (
        <Paper className={classes.paper}>
          <Content />
        </Paper>
      )}
    </Container>
  );
}
