import Decimal from 'decimal.js-light';
import { secondsToDurationString, toDateTimeString } from '../../../helpers';
import Battery0 from '../../../images/battery-0-light.svg';
import Battery1 from '../../../images/battery-1-light.svg';
import Battery2 from '../../../images/battery-2-light.svg';
import Battery3 from '../../../images/battery-3-light.svg';
import Battery4 from '../../../images/battery-4-light.svg';
import Battery5 from '../../../images/battery-5-light.svg';

export function getTemperatureText({ temp = undefined, tempUnit = 'F' }) {
  if (temp === undefined || temp === null) return 'UNKNOWN';
  const c = new Decimal(temp);
  if (tempUnit === 'F') {
    const f = c.times(9).dividedBy(5).add(32);
    return f.toInteger().toString() + '° F';
  }
  return c.toInteger().toString() + '° C';
}

export function getWatts(milliwatts = undefined) {
  return milliwatts === undefined || milliwatts === null ? 'UNKNOWN' : `${new Decimal(milliwatts).dividedBy(1000).toDecimalPlaces(1).toString()} W`;
}

export const getBatteryLevel = ({ batt_v = 0 }) => {
  var decVolts = new Decimal(batt_v);
  if (decVolts.lessThan(9.0)) return [Battery0, 'EMPTY'];
  if (decVolts.lessThan(10.0)) return [Battery1, 'LOW'];
  if (decVolts.lessThan(10.67)) return [Battery2, 'MID'];
  if (decVolts.lessThan(11.33)) return [Battery3, 'MID'];
  if (decVolts.lessThan(12.0)) return [Battery4, 'MID'];
  else return [Battery5, 'FULL'];
};

/**
 * @param ts either an ISO date-time string or seconds since the (micro) epoch
 *           (i.e. 2000-01-01 vice standard POSIX epoch of 1970-01-01)
 * @returns seconds elapsed since the given timestamp
 */
export function secondsSince(ts: string | number) {
  return (new Date().getTime() - parseDate(ts).getTime()) / 1000;
}

/**
 *
 * @param ts2k timestamp in seconds since 2000-01-01 (epoch used by micropython ESP32 port)
 * @returns
 */
export function parseDate(ts: string | number) {
  if (typeof ts === 'number') {
    // (micro) epoch time
    const d = new Date('2000-01-01 00:00:00 UTC');
    d.setUTCSeconds(d.getUTCSeconds() + ts);
    return d;
  } else {
    // ISO date string
    return new Date(ts);
  }
}

export function getStatusText(offline: boolean, telem: any, schedule: any = {}) {
  const { status, active_cleaning_idx, active_seconds_remaining, paused_seconds_remaining, idle_seconds_remaining } = schedule;
  const secondaryStatusUnknown = 'Please ensure your Skimdevil is powered on and connected to the network.';

  if (offline) {
    const { ts, resume_schedule } = telem;
    const offlineSeconds = offline ? secondsSince(ts) : 0;
    const offlineDurationStr = offline ? secondsToDurationString(offlineSeconds) : '';
    const idleRemaining = idle_seconds_remaining - offlineSeconds;
    const idleRemainingDurationStr = secondsToDurationString(idleRemaining);
    const batteryLow = getBatteryLevel(telem)[0] === Battery0;

    return {
      status: 'Skimdevil is Offline',
      primary: `Last status reported ${toDateTimeString(parseDate(ts))} (${offlineDurationStr} ago).`,
      secondary: batteryLow
        ? `Your Skimdevil's battery may be too low to begin/resume cleaning.`
        : resume_schedule === undefined
        ? secondaryStatusUnknown
        : resume_schedule
        ? idleRemaining > 0
          ? `Your Skimdevil is scheduled to resume cleaning in ${idleRemainingDurationStr}.`
          : secondaryStatusUnknown
        : 'Power on your Skimdevil and press START to begin/resume cleaning.',
      offlineDurationStr
    };
  } else if (status === 'STOPPED') {
    return {
      status,
      primary: 'Your Skimdevil is currently stopped.',
      secondary: 'Press START to begin/resume cleaning.'
    };
  } else if (status === 'CLEANING' && active_cleaning_idx === -1) {
    return {
      status,
      primary: 'Your Skimdevil has started cleaning!',
      secondary: `There ${secondsToDurationString(active_seconds_remaining, true)} remaining in this cycle.`
    };
  } else if (status === 'CLEANING') {
    return {
      status,
      primary: `Your Skimdevil has started Cleaning ${active_cleaning_idx + 1}.`,
      secondary: `There ${secondsToDurationString(active_seconds_remaining, true)} remaining in this cycle.`
    };
  } else if (status === 'PAUSED') {
    return {
      status,
      primary: `Your Skimdevil will resume Cleaning ${active_cleaning_idx + 1} in ${secondsToDurationString(paused_seconds_remaining)}.`,
      secondary: undefined
    };
  } else if (status === 'IDLE') {
    return {
      status,
      primary: 'Your Skimdevil is waiting for the next cleaning window.',
      secondary: `There ${secondsToDurationString(idle_seconds_remaining, true)} remaining.`
    };
  } else if (status === 'LOWPOWER') {
    var inCleaningWindow = active_seconds_remaining > -1 || paused_seconds_remaining > -1;
    return {
      status: 'LOW BATTERY',
      primary: `Your Skimdevil's battery is low`,
      secondary: inCleaningWindow ? 'Cleaning will resume when charged/charging' : undefined
    };
  } else {
    return {
      status: 'UNKNOWN',
      primary: 'Unknown Skimdevil status.',
      secondary: secondaryStatusUnknown
    };
  }
}
