import * as React from 'react';
import { Route, Switch } from 'react-router';
import Logout from './Logout';
import Overview from '../pages/Overview/Overview';
import DeviceSettings from '../pages/DeviceSettings';
import AccountSettings from '../pages/AccountSettings';

const Routes = () => (
  <Switch>
    <Route exact path='/DeviceSettings' component={DeviceSettings} />
    <Route exact path='/AccountSettings' component={AccountSettings} />
    <Route exact path='/Logout' component={Logout} />
    <Route component={Overview} />
  </Switch>
);

export default Routes;
