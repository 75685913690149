import * as React from 'react';
import { useChannelOut, useProductId, useChannelIn } from '../../layout/store';
import axios from '../../axios/config';

export type TelemMsg = { id: string; message: { data: Telemetry } };
export type Telemetry = {
  batt_v: number;
  solar_p: number;
  temp: number;
  ts: Date;
  schedule: any;
};

export default function useTelemetry() {
  const telemMsg = useChannelOut({ type: 'telemetry' }).lastMessageOnTopic as TelemMsg;
  const pingMsg = useChannelOut({ type: 'status' }).lastMessageOnTopic;
  const productId = useProductId();
  const [state, setState] = React.useState({ offline: true, telem: {} as Telemetry, id: undefined, pingId: undefined });
  const { subscribed, handlePublish } = useChannelIn();  

  React.useEffect(() => {
    if (!productId) {
      return;
    }

    const fetchOfflineTelem = async () => {
      const method = 'get';
      const url = `https://api.skimdevil.com/v1/products/${productId}/telem?debug=true`;
      const result = await axios({ method, url });
      if (result.status === 200 && !telemMsg) {
        setState(state => ({ ...state, telem: _buildTelem(result) }));
      } else {
        console.error('Error fetching offline telemetry:', { result });
      }
    };

    if (telemMsg?.message?.data && telemMsg.id !== state.id) {
      const telem = telemMsg.message.data as Telemetry;
      const newer = typeof state.telem.ts === 'string' || telem.ts > state.telem.ts;
      if (state.offline || newer) {
        setState(state => ({ ...state, offline: false, telem, id: telemMsg.id }));
      }
    } else if (state.offline && !telemMsg) {
      fetchOfflineTelem();
    }

    const doPing = () => {
      if (document.hidden) {
        return;
      }
      if (subscribed) {
        // version isn't actually used; just a lightweight way to check if bot is online
        handlePublish(`{"type": "get_version"}`);
      }
      const offline = pingMsg?.id === state.pingId && telemMsg?.id === state?.id;
      setState(state => ({ ...state, offline, pingId: pingMsg?.id }));
    };

    const interval = setInterval(doPing, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, telemMsg, pingMsg, state.pingId, state.offline]);

  return state;
}

const _buildTelem = (result: any): Telemetry => {
  const data = {} as Telemetry;
  Object.entries(result.data).forEach(([_k_, v]) => {
    var [k, _k] = _k_.split('__');
    if (!data[k]) {
      data[k] = {};
    }
    if (_k) {
      data[k][_k] = v;
    } else {
      data[k] = v;
    }
  });
  return data;
};
