import * as React from 'react';
import Slider from './Slider';

const marks = [
    {
        value: 0,
        label: 'CLOSE',
    },
    {
        value: 50,
        label: 'DEFAULT',
    },
    {
        value: 100,
        label: 'FAR',
    },
];

const PoolEdgeSensor = ({ getFieldState = undefined, ...props }) => <Slider marks={marks} step={25} {...props} />;
export default PoolEdgeSensor