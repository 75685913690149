export const timezones = [
  { label: '(GMT-12:00) Eniwetok, Kwajalein', value: 60 * 12 },
  { label: '(GMT-11:00) Midway Island, Samoa', value: 60 * 11 },
  { label: '(GMT-10:00) Hawaii', value: 60 * 10 },
  { label: '(GMT-09:00) Alaska', value: 60 * 9 },
  { label: '(GMT-08:00) Pacific Time (US & Canada)', value: 60 * 8 },
  { label: '(GMT-07:00) Mountain Time (US & Canada)', value: 60 * 7 },
  { label: '(GMT-06:00) Central Time (US & Canada)', value: 60 * 6 },
  { label: '(GMT-05:00) Eastern Time (US & Canada)', value: 60 * 5 },
  { label: '(GMT-04:00) Atlantic Time (Canada)', value: 60 * 4 },
  { label: '(GMT-03:30) Newfoundland', value: 60 * 3 + 30 },
  { label: '(GMT-03:00) Greenland', value: 60 * 3 },
  { label: '(GMT-02:00) Mid-Atlantic', value: 60 * 2 },
  { label: '(GMT-01:00) Cape Verde Is.', value: 60 * 1 },
  { label: '(GMT) Greenwich Mean Time', value: 60 * 0 },
  { label: '(GMT+01:00) Amsterdam, Berlin, Bern', value: 60 * -1 },
  { label: '(GMT+02:00) Athens, Istanbul, Minsk', value: 60 * -2 },
  { label: '(GMT+03:00) Moscow, St. Petersburg', value: 60 * -3 },
  { label: '(GMT+03:30) Tehran', value: 60 * -3 - 30 },
  { label: '(GMT+04:00) Baku, Tbilisi, Yerevan', value: 60 * -4 },
  { label: '(GMT+04:30) Kabul', value: 60 * -4 - 30 },
  { label: '(GMT+05:00) Ekaterinburg', value: 60 * -5 },
  { label: '(GMT+05:30) Calcutta, Chennai, Mumbai', value: 60 * -5 - 30 },
  { label: '(GMT+05:45) Kathmandu', value: 60 * -5 - 45 },
  { label: '(GMT+06:00) Almaty, Novosibirsk', value: 60 * -6 },
  { label: '(GMT+06:30) Rangoon', value: 60 * -6 - 30 },
  { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 60 * -7 },
  { label: '(GMT+08:00) Beijing, Chongqing, Hong Kong', value: 60 * -8 },
  { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 60 * -9 },
  { label: '(GMT+09:30) Adelaide', value: 60 * -9 - 30 },
  { label: '(GMT+10:00) Brisbane', value: 60 * -10 },
  { label: '(GMT+11:00) Magadan, Solomon Is.', value: 60 * -11 },
  { label: '(GMT+12:00) Fiji, Kamchatka', value: 60 * -12 },
];

export default timezones;
