import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as Icons from '@material-ui/icons';
import * as LayoutStore from './store';
import { makeStyles, createStyles, Theme, Typography } from '@material-ui/core';
import { isAdmin } from '../../buildTarget';

const appItems = [
  {
    nodeId: 'overview',
    labelText: 'Overview',
    labelIcon: Icons.Home,
    route: '/Overview',
    indent: 0
  },
  {
    nodeId: 'deviceSettings',
    labelText: 'Device Settings',
    labelIcon: Icons.Settings,
    route: '/DeviceSettings',
    indent: 0
  },
  {
    nodeId: 'accountSettings',
    labelText: 'Account Settings',
    labelIcon: Icons.Person,
    route: '/AccountSettings',
    indent: 0
  }
];

const adminItems = [
  {
    nodeId: 'factoryBots',
    labelText: 'Factory Bots',
    labelIcon: Icons.Wifi,
    route: '/FactoryBots',
    indent: 0
  },
  {
    nodeId: 'clientBots',
    labelText: 'Client Bots',
    labelIcon: Icons.Language,
    route: '/ClientBots',
    indent: 0
  },
  {
    nodeId: 'playGround',
    labelText: 'PlayGround',
    labelIcon: Icons.DeveloperBoard,
    route: '/PlayGround',
    indent: 0
  }
];

const items = isAdmin ? adminItems : appItems;

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  indent: number;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      '&:focus > $content': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        color: 'var(--tree-view-color)'
      }
    },
    content: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular
      }
    },
    groupOld: {
      marginLeft: 0,
      '& $content': {
        paddingLeft: theme.spacing(2)
      }
    },
    group: (props: ComponentProps) => ({
      paddingLeft: (props as any).indent
    }),
    expanded: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit'
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center'
    },
    labelIcon: {
      marginRight: theme.spacing(1)
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1
    }
  })
);

function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles(props);
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color='inherit' className={classes.labelIcon} />
          <Typography variant='body2' className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant='caption' color='inherit'>
            {labelInfo}
          </Typography>
        </div>
      }
      style={
        {
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor
        } as any
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label
      }}
      {...other}
    />
  );
}

interface ComponentProps {
  changeRoute?: (route: string) => void;
  route?: string;
  children?: any;
  classes?: any;
}

const useStyles = makeStyles({
  root: {
    height: 216,
    flexGrow: 1,
    maxWidth: 400
  }
});

function NavigationTreeView(props: ComponentProps) {
  const classes = useStyles({});
  const { pathname } = useLocation();
  const history = useHistory();

  const [leftDrawer, setLeftDrawer] = LayoutStore.useLeftDrawer();

  const [, setState] = LayoutStore.useRouteState();

  const changeRoute = React.useCallback(
    (route: string, state = undefined) => event => {
      leftDrawer && setLeftDrawer(false);
      pathname !== route && history && history.push(route); //only change pages if it is different than the current page
      if (state !== undefined) setState(state);
    },
    [history, pathname, leftDrawer, setLeftDrawer, setState]
  );

  return (
    <TreeView className={classes.root} defaultCollapseIcon={<Icons.ExpandMore />} defaultExpandIcon={<Icons.ChevronRight />}>
      {items.map(({ nodeId, labelText, labelIcon, route, indent }) => (
        <StyledTreeItem
          key={nodeId}
          nodeId={nodeId}
          labelText={labelText}
          labelIcon={labelIcon}
          onClick={changeRoute(route, { backRoute: route })}
          indent={indent}
        />
      ))}
    </TreeView>
  );
}

export default NavigationTreeView;
