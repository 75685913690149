import React from 'react';
import GenericDialog from '../GenericDialog';
import { Box, Button, CircularProgress, createStyles, DialogContent, LinearProgress, makeStyles, Typography, useTheme } from '@material-ui/core';
import { useDialogState } from '../actions';
import { canUpdateVersion, isEmpty } from '../../../../helpers';
import useBotFirmware from './useBotFirmware';
import useVersionInfo from './useVersionInfo';

export const title = 'Firmware Version';

const useStyles = makeStyles(({ palette, layout }: any) =>
  createStyles({
    submitProgress: {
      color: palette.primary.light,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -(layout.progressSize / 2),
      marginLeft: -(layout.progressSize / 2)
    }
  })
);

function FirmwareProgress({ value }) {
  return (
    <Box display='flex' alignItems='center'>
      <Box width='100%' mr={1}>
        <LinearProgress variant='determinate' value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant='body2' color='textSecondary'>{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const FirmwareVersion = () => {
  const classes = useStyles();
  const [{ open, loading, version = '' }, setDialogState] = useDialogState(title);

  const { rebooting, loading: firmwareUpdating, progress, subscribed, handleFirmwareUpdate } = useBotFirmware({ open, setDialogState });

  const versionInfo: any = useVersionInfo({ trigger: open });
  const newestVersion = versionInfo?.version ?? '';

  var canUpdate = canUpdateVersion(version, newestVersion);

  const inProgress = subscribed !== true || loading === true || isEmpty(version) || isEmpty(newestVersion);
  const { layout } = useTheme() as any;

  return (
    <DialogContent>
      {rebooting || firmwareUpdating ? (
        <Box mt={2} mb={2}>
          <Typography variant='h6' color='primary'>
            {rebooting ? 'Skim devil is rebooting, please wait...' : 'Updating firmware, please wait...'}
          </Typography>
          <Box mt={1}>
            <FirmwareProgress value={progress ?? 0} />
          </Box>
        </Box>
      ) : (
        <>
          {inProgress && <CircularProgress size={layout.progressSize} className={classes.submitProgress} />}
          <Box mt={1} mb={1}>
            <Typography color={isEmpty(version) ? 'textSecondary' : 'textPrimary'} variant='h6'>{`Your Skimdevil version is ${version}`}</Typography>
            <Box mt={1}>
              <Typography color={isEmpty(newestVersion) ? 'textSecondary' : 'textPrimary'} variant='h6'>{`The newest version is ${newestVersion}`}</Typography>
            </Box>
            <Box mt={2} textAlign='center'>
              {inProgress === true ? (
                <Typography variant='h6' color='primary'>
                  Loading version information, please wait...
                </Typography>
              ) : canUpdate ? (
                <Button disabled={inProgress || firmwareUpdating} onClick={handleFirmwareUpdate} variant='contained' color='primary'>
                  Click to Update
                </Button>
              ) : (
                <Typography variant='h6' color='primary'>
                  Your firmware is up to date!
                </Typography>
              )}
            </Box>
          </Box>
        </>
      )}
    </DialogContent>
  );
};

export default function FirmwareVersionDialog({ id = title, onClose, ...other }) {
  return (
    <GenericDialog id={id} title={title} onClose={onClose} initialValues={{}} submitLabel={null} cancelLabel='Close' Content={FirmwareVersion} {...other} />
  );
}
