import * as React from 'react';
import Text from './Text';
import Decimal from 'decimal.js-light';
import { isEmpty } from '../../../helpers';

// Ensure the value is either null, undefined, empty string or a number converted to a string
const ensureEmptyOrString = value => {
  return isEmpty(value) ? value : !isNaN(value) ? value.toString() : value;
};

// Ensures the value is either empty or a string time value which includes an hour/minute : separator.
const ensureEmptyOrTimeFormat = value => {    
    return isEmpty(value) || value.includes(':') ? value : value.replace(/(..)$/, ':$1');
}

const formatValue = value => ensureEmptyOrTimeFormat(ensureEmptyOrString(value)); // First ensures string format, then ensures time format

export default function Time({ value = undefined, onChange, inputProps = undefined, ...other }) {
  const handleChange = React.useCallback(
    event => {
      const newValue = isEmpty(event.target.value) ? event.target.value : new Decimal(event.target.value.replace(':', '')).toNumber();
      onChange && onChange({ target: { value: newValue } }); // Ensure a number is sent instead of a string
    },
    [onChange]
  );
  return <Text type='time' value={formatValue(value)} inputProps={{ step: 300, ...inputProps }} onChange={handleChange} {...other} />;
}

