import * as React from 'react';
import { makeStyles, Grid, Paper, Typography, Divider } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import BrandLogoImage from '../../images/full_logo.png';
import Login from './Login';
import CreateAccount from './CreateAccount';
import ForgotPassword from './ForgotPassword';
import { useUrlParameter } from '../../hooks';
import { isEmpty } from '../../helpers';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(({ palette, spacing, mixins }: any) =>
    createStyles({
        root: {
            background: palette.common.white,
            color: palette.primary.main,
            height: '100vh',
        },
        paper: {
            marginTop: 32,
            width: 300,
        },
        panelarea: {
            background: '#F5F5F5',
            padding: 16,
        },
        button: {
            marginTop: 8,
            width: 240,
        },
        toolbar: mixins.toolbar,
        wrapper: {
            margin: spacing(1),
            position: 'relative',
        },
    })
);

export default function Intro() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const b64ParamString = useUrlParameter('o');
    const params = b64ParamString ? JSON.parse(atob(b64ParamString as string)) : {} as any;
    const { register, login, email, success, error } = params;
    const [{ page }, setState] = React.useState({ page: 0 });

    const handleCreateAccount = React.useCallback(() => {
        setState({ page: 2 })
    }, [setState]);

    const handleLogin = React.useCallback(() => {
        setState({ page: 1 })
    }, [setState]);

    React.useEffect(() => {
        if (!isEmpty(email)) {
            if (login)
                handleLogin(); // If a username is provided, automatically advance to login page
            else if (register)
                handleCreateAccount();
        }
    }, [login, register, email, handleCreateAccount, handleLogin])

    const handleBack = React.useCallback(() => {
        dispatch(push('')); // This clears any query params
        setState({ page: 0 })
    }, [dispatch, setState]);

    const handleForgotPassword = React.useCallback(() => {
        dispatch(push('')); // This clears any query params
        setState({ page: 3 })
    }, [dispatch, setState]);

    return page === 1 ? <Login email={email} success={success} error={error} onBack={handleBack} onForgotPassword={handleForgotPassword} /> : page === 2 ? <CreateAccount email={email} success={success} error={error} onBack={handleBack} onSuccess={handleLogin} /> : page === 3 ? <ForgotPassword onBack={handleLogin} /> : (
        <div className={classes.root} >
            <div className={classes.toolbar} />
            <form autoComplete='off'>
                <Grid container direction='column' justify='center' alignItems='center'>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Typography align='center' className={classes.panelarea}>
                                <img src={BrandLogoImage} alt='logo' style={{ height: 44 }} />
                            </Typography>
                            <Divider />
                            <Grid container direction='column' justify='center' alignItems='center' style={{ padding: 16 }}>
                                <Grid item>
                                    <div className={classes.wrapper}>
                                        <Button
                                            color='primary'
                                            variant='contained'
                                            className={classes.button}
                                            onClick={handleLogin}
                                        >
                                            Login
                                        </Button>
                                    </div>
                                </Grid>
                                {/* <Grid item>
                                    <div className={classes.wrapper}>
                                        <Button
                                            color='primary'
                                            variant='contained'
                                            className={classes.button}
                                            onClick={handleCreateAccount}
                                        >
                                            Create Account
                                        </Button>
                                    </div>
                                </Grid> */}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}
