import * as React from 'react';
import { Typography, Grid } from '@material-ui/core';
import OutlinedDiv from '../../general/OutlinedDiv/OutlinedDiv';
import { getStatusText } from './helpers';

export default function CleaningStatus({ telemData }) {
  const { status = 'UNKNOWN', primary, secondary } = getStatusText(telemData?.offline, telemData?.telem, telemData?.telem?.schedule);
  return (
    <OutlinedDiv label={`STATUS`}>
      <Grid container spacing={1} style={{ marginTop: 8, marginBottom: 8 }}>
        <Grid item xs={12}>
          <Typography color='primary'>{status}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle2' color='secondary'>
            {primary}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' color='secondary'>
            {secondary}
          </Typography>
        </Grid>
      </Grid>
    </OutlinedDiv>
  );
}
