import * as React from 'react';
import { Reducer } from 'redux';
import { AppThunkAction, AppState } from '../../store';
import { isEmpty, triggerResize } from '../../helpers';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import useRequest from '../axios/useRequest';
import { useSubscription } from '../mqtt';
import { getDefaultBotName } from '../application/GenericDialog/BotName/BotName';
import { theme } from '../../constants';
import useSubscriptionWithLogs from '../mqtt/useSubscriptionWithLogs';

export type ViewMode = 'table' | 'list';

export interface ClientAppState {
  height?: number;
  width?: number;
  appBarHeight?: number;
  footerHeight?: number;
  headerHeight?: number;
  snackbaropen?: boolean;
  snackbarmessage?: string;
  snackbarmessagetype?: string;
  auth?: any;
  leftDrawerOpen?: boolean;
  rightDrawerOpen?: boolean;
  rightDrawerOpenPermanent?: boolean;
  household?: any;
  botId?: string;
  tempUnit?: string;
  viewMode?: ViewMode;
  routeState?: any;
  versionInfo?: any; // Holds the latest version info from api request
}

const defaultState = {
  width: 0,
  height: 0,
  appBarHeight: (theme as any).layout.toolbarheight,
  footerHeight: (theme as any).layout.footerHeight,
  headerHeight: (theme as any).layout.headerHeight,
  snackbaropen: false,
  snackbarmessage: null,
  leftDrawerOpen: false,
  rightDrawerOpen: false, //temporary drawer for small screen sizes
  rightDrawerOpenPermanent: false, //permanent drawer for large screen sizes
  auth: null,
  tempUnit: 'F',
  household: undefined,
  //botId: '10521c5452cc', // PCB
  //botId: '10521c537a14',
  //botId: '10521c52f9c8',
  viewMode: 'table',
  routeState: {},
  versionInfo: {},
  batchSize: 5
};

export type State = ClientAppState;

interface IResizeViewPort {
  type: 'RESIZE_VIEWPORT';
  height: number;
  width: number;
}

export interface ILogin {
  type: 'LOGIN';
  auth: any;
}
export interface ILogout {
  type: 'LOGOUT';
}

export interface ISnackbar {
  type: 'SNACKBAR';
  message: string;
  messagetype?: string;
}

interface IHandleDrawerToggle {
  type: 'HANDLE_DRAWER_TOGGLE';
  drawer: string;
  open?: boolean; //Optional.  If specified always set drawer value to value passed, otherwise toggle state value
}
interface IProcessData {
  type: 'PROCESS_DATA';
  models: string[];
  results: any[];
}

interface IUpdateLayout {
  type: 'UPDATE_LAYOUT';
  payload: any;
}

export interface ProcessDataInfo {
  Model: string;
  Action?: string; // If not specified the server will default to "r" for read
  Alert?: boolean; // If true, an alert will be showing indicating the status of the response
  FilterResult?: boolean; // If true, the result will not be written to the local state
  Method?: string;
  ServerGUID?: string;
  Data?: any;
  onSuccess?: any;
  onError?: any;
  onBlob?: any;
}

type KnownAction = IResizeViewPort | IHandleDrawerToggle | ILogin | ILogout | ISnackbar | IProcessData | IUpdateLayout;

////////////////
//// Hooks ////
///////////////

export const useLogin = ({ setState = undefined, onSuccess = undefined }) => {
  const dispatch = useDispatch();

  const onLogin = React.useCallback(
    response => {
      dispatch({ type: 'LOGIN', auth: response.data });
      console.log('Successfully logged in.');
      onSuccess && onSuccess();
    },
    [dispatch, onSuccess]
  );

  const { handleRequest, error, setError } = useRequest({ url: '/auth/login?debug=true', setState, onSuccess: onLogin });

  return { handleLogin: handleRequest, error, setError };
};

export const useProfileData = ({ setState = undefined, onSuccess = undefined }) => {
  const [profile, setProfile] = React.useState({});

  const handleSuccess = React.useCallback(
    response => {
      setProfile(response.data);
      onSuccess && onSuccess();
    },
    [setProfile, onSuccess]
  );

  const { handleRequest, error } = useRequest({ method: 'GET', url: '/v1/profiles?debug=true', setState, onSuccess: handleSuccess });

  const { handleRequest: handleUpdate, error: updateError } = useRequest({ method: 'PUT', url: '/v1/profiles?debug=true', setState, onSuccess: handleSuccess });
  const { handleRequest: handleDelete, error: deleteError } = useRequest({ method: 'DEL', url: '/v1/profiles?debug=true', setState, onSuccess: handleSuccess });

  return { handleRequest, handleUpdate, handleDelete, profile, setProfile, error, updateError, deleteError };
};

export const useHousehold = ({ id = undefined, state: State = undefined, setState: SetState = undefined, onSuccess = undefined } = {}) => {
  const url = `/v1/households/${id}?debug=true`;
  const [internalState, setInternalState] = React.useState({ success: false, disabled: false, submitting: false, response: undefined });
  const setState = SetState ?? setInternalState;
  const state = State ?? internalState;

  const { response } = state;
  const household = response?.data || [];

  const handleSuccess = React.useCallback(
    response => {
      onSuccess && onSuccess();
    },
    [onSuccess]
  );

  const { handleRequest: HandleRequest, error } = useRequest({ method: 'GET', url, setState, onSuccess });
  const { handleRequest: handleUpdate, error: updateError } = useRequest({
    method: 'PUT',
    url,
    setState,
    onSuccess: handleSuccess
  });

  // This simply clears the household data before
  const handleRequest = React.useCallback(
    (values = {}, OnSuccess = undefined, OnError = undefined) => {
      if (isEmpty(id)) {
        console.error('No household id provided');
      } else {
        setState(prev => ({ ...prev, households: [] }));
        HandleRequest(values, OnSuccess, OnError);
      }
    },
    [HandleRequest, setState, id]
  );

  return { handleRequest, handleUpdate, updateError, household, state, setState, error };
};

export const useHouseholds = ({ setState: SetState = undefined, onSuccess = undefined } = {}) => {
  const [state, setInternalState] = React.useState({ success: false, disabled: false, submitting: false, response: undefined });
  const setState = SetState ?? setInternalState;

  const { response } = state;
  const households = response?.data || [];

  const { handleRequest: HandleRequest, error } = useRequest({ method: 'GET', url: '/v1/households?debug=true', setState, onSuccess });

  // This simply clears the household data before
  const handleRequest = React.useCallback(
    (values = {}, OnSuccess = undefined, OnError = undefined) => {
      setState(prev => ({ ...prev, households: [] }));
      HandleRequest(values, OnSuccess, OnError);
    },
    [HandleRequest, setState]
  );

  return { handleRequest, households, state, setState, error };
};

export const useCreateHousehold = ({ setState: SetState = undefined, onSuccess = undefined } = {}) => {
  const [state, setInternalState] = React.useState({ success: false, disabled: false, submitting: false });
  const setState = SetState ?? setInternalState;

  const { handleRequest: handleCreate, error } = useRequest({ method: 'POST', url: '/v1/households?debug=true', setState, onSuccess });
  //const { handleRequest: handleUpdate, error: updateError } = useRequest({ method: 'PUT', url: '/v1/households?debug=true', setState });
  //const { handleRequest: handleDelete, error: deleteError } = useRequest({ method: 'DEL', url: '/v1/households?debug=true', setState });

  return { handleCreate, error, state, setState };
};

export const useResizeViewPort = () => {
  const dispatch = useDispatch();
  return React.useCallback(props => dispatch({ type: 'RESIZE_VIEWPORT', ...props }), [dispatch]);
};

export const useLayoutKey = key => useSelector((state: AppState) => state.layout[key], shallowEqual);

export const useAuth = () => useLayoutKey('auth') || {};

export const useProduct = () => {
  const household = useLayoutKey('household');
  return (household?.products?.length ?? 0) > 0 ? household.products[0] : {};
};

export const useBotName = () => {
  const { nickname } = useProduct();
  const household = useLayoutKey('household');
  return getDefaultBotName(nickname, household === undefined);
};

export const useBotId = ({ BotId = undefined } = {}) => {
  const { bot_id } = useProduct();
  return BotId ?? bot_id;
};

export const useProductId = () => {
    const { id } = useProduct();
    return id;
};

export const useChannels = ({ BotId = undefined } = {} as any) => {
  const botId = useBotId({ BotId });
  return {
    channelAllOut: `+/out`,
    channelOut: `${botId}/out`,
    channelIn: `${botId}/in`,
    channelGeneral: 'bot_general'
  };
};

export const useChannelIn = ({ BotId = undefined, ...other } = {} as any) => {
  const { channelIn } = useChannels({ BotId });
  return useSubscription({ topic: channelIn, ...other });
};

// Use separate hook to ensure existing behavior of client app
export const useChannelInWithLogs = ({ BotId = undefined, ...other } = {} as any) => {
  const { channelIn } = useChannels({ BotId });
  return useSubscriptionWithLogs({ topic: channelIn, ...other });
};

export const useChannelOut = ({ BotId = undefined, ...other } = {} as any) => {
  const { channelOut } = useChannels({ BotId });
  return useSubscription({ topic: channelOut, ...other });
};

export const useChannelOutWithLogs = ({ BotId = undefined, ...other } = {} as any) => {
  const { channelOut } = useChannels({ BotId });
  return useSubscriptionWithLogs({ topic: channelOut, ...other });
};

export const useChannelAllOut = ({ BotId = undefined, ...other } = {} as any) => {
  const { channelAllOut } = useChannels({ BotId });
  return useSubscription({ topic: channelAllOut, ...other });
};

export const useChannelAllOutWithLogs = ({ BotId = undefined, ...other } = {} as any) => {
  const { channelAllOut } = useChannels({ BotId });
  return useSubscriptionWithLogs({ topic: channelAllOut, ...other });
};

export const useChannelGeneral = ({ BotId = undefined, ...other } = {} as any) => {
  const { channelGeneral } = useChannels({ BotId });
  return useSubscription({ topic: channelGeneral, ...other });
};

export const useChannelGeneralWithLogs = ({ BotId = undefined, ...other } = {} as any) => {
  const { channelGeneral } = useChannels({ BotId });
  return useSubscriptionWithLogs({ topic: channelGeneral, ...other });
};

export const useLayout = (): any[] => {
  const dispatch = useDispatch();
  const layout = useSelector((state: AppState) => state.layout, shallowEqual);
  const setLayout = React.useCallback(
    payload => {
      dispatch(updateLayout(payload));
    },
    [dispatch]
  );
  return [layout, setLayout];
};

export const useLogout = () => {
  const dispatch = useDispatch();
  return React.useCallback(() => dispatch(actionCreators.ALogout()), [dispatch]);
};

export const useHeight = (): number => {
  return useSelector((state: AppState) => state.layout.height);
};

export const useWidth = (): number => {
  return useSelector((state: AppState) => state.layout.width);
};

export const useDimensions = () => {
  return [useHeight(), useWidth()];
};

export const useRouteState = () => {
  const dispatch = useDispatch();
  const setState = React.useCallback(state => dispatch({ type: 'ROUTE_STATE', state }), [dispatch]);
  const state = useSelector((state: AppState) => state.layout.routeState);
  return [state, setState];
};

export const useViewMode = () => {
  const dispatch = useDispatch();
  const setViewMode = React.useCallback(mode => dispatch(changeViewMode(mode)), [dispatch]);
  const viewMode = useSelector((state: AppState) => state.layout.viewMode);
  return [viewMode, setViewMode];
};

export const useResizeHeader = () => {
  const dispatch = useDispatch();
  return React.useCallback(height => dispatch(resizeHeader(height)), [dispatch]);
};

export const useResizeAppBar = () => {
  const dispatch = useDispatch();
  return React.useCallback(height => dispatch(resizeAppBar(height)), [dispatch]);
};

export const useAppBarHeight = (): number => {
  return useSelector((state: AppState) => state.layout.appBarHeight);
};

export const useFooterHeight = (): number => {
  return useSelector((state: AppState) => state.layout.footerHeight);
};

export const useHeaderHeight = (): number => {
  return useSelector((state: AppState) => state.layout.headerHeight);
};

export const useLeftDrawer = (): any[] => {
  const [{ leftDrawerOpen }, setLayout] = useLayout();
  const setLeftDrawerOpen = React.useCallback((open = !leftDrawerOpen) => setLayout({ leftDrawerOpen: open }), [setLayout, leftDrawerOpen]);
  return [leftDrawerOpen, setLeftDrawerOpen];
};

export const useProcessDataHandle = () => {
  //const dispatch = useDispatch();
  return React.useCallback(pdis => console.log('TO BE COMPLETED'), [
    //dispatch
  ]);
};

export const useProcessData = () => {
  //const dispatch = useDispatch();
  return React.useCallback(pdis => console.log('TO BE COMPLETED'), [
    //dispatch
  ]);
};

export const updateLayout = payload => ({ type: 'UPDATE_LAYOUT', payload });

export const actionCreators = {
  ALogout: (): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
    dispatch({ type: 'LOGOUT' });
  },
  ASnackbar: (message: string, messagetype?: string): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
    dispatch({ type: 'SNACKBAR', message: message, messagetype: messagetype });
  },
  AHandleDrawerToggle: (drawer: string, open?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: 'HANDLE_DRAWER_TOGGLE', drawer, open });
    triggerResize();
  }
};

export const changeViewMode = (mode: ViewMode) => ({ type: 'CHANGE_VIEW_MODE', mode });
export const resizeHeader = (height: number | undefined) => ({ type: 'RESIZE_HEADER', height });
export const resizeViewPort = (height: number | undefined, width: number | undefined) => ({ type: 'RESIZE_VIEWPORT', height: height, width: width });
export const resizeAppBar = (height: number | undefined) => ({ type: 'RESIZE_APPBAR', height });
export const resizeFooter = (height: number | undefined) => ({ type: 'RESIZE_FOOTER', height });

export const reducer: Reducer<State> = (state: State | any, action: KnownAction | any): any => {
  switch (action.type) {
    case 'ROUTE_STATE':
      return {
        ...state,
        routeState: action.state
      };
    case 'CHANGE_VIEW_MODE':
      return {
        ...state,
        viewMode: action.mode
      };
    case 'RESIZE_APPBAR':
      return {
        ...state,
        appBarHeight: action.height
      };
    case 'RESIZE_FOOTER':
      return {
        ...state,
        footerHeight: action.height
      };
    case 'RESIZE_HEADER':
      return {
        ...state,
        headerHeight: action.height
      };
    case 'UPDATE_LAYOUT':
      return {
        ...state,
        ...action.payload
      };
    case 'RESIZE_VIEWPORT':
      return {
        ...state,
        height: action.height,
        width: action.width
      };
    case 'LOGIN':
      return {
        ...state,
        auth: action.auth
      };
    case 'LOGOUT':
      return {
        ...defaultState,
        auth: undefined,
        height: state.height, //keep keight and width, otherwise the page will render incorrectly
        width: state.width
      };
    case 'SNACKBAR':
      return {
        ...state,
        snackbarmessage: action.message,
        snackbaropen: action.message ? true : false,
        snackbarmessagetype: action.messagetype ? action.messagetype : state.snackbarmessagetype
      };
    case 'PROCESS_DATA':
      return {
        ...state,
        ...action.results
      };

    default:
    // The following line guarantees that every action in the KnownAction union has been covered by a case above
    //const exhaustiveCheck: never = action;
  }
  return state || { ...defaultState };
};
