import * as React from 'react';
import Decimal from 'decimal.js-light';
import { checkEmpty, localTimeToUtc, utcTimeToLocal } from '../../../helpers';
import Time from './Time';

const formatValue = value => (checkEmpty(value) ? value : utcTimeToLocal(new Decimal(isNaN(value) ? value.replace(':', '') : value).toNumber())); // Converts from UTC to local

export default function UtcTime({ value = undefined, onChange, ...other }) {
  const handleChange = React.useCallback(
    event => {
      // Convert the value from local to utc
      const newValue = checkEmpty(event.target.value)
        ? event.target.value
        : localTimeToUtc(new Decimal(isNaN(event.target.value) ? event.target.value.replace(':', '') : event.target.value).toNumber());
      onChange && onChange({ target: { value: checkEmpty(newValue) ? newValue : Number(newValue) } }); // Ensure a number is sent instead of a string
    },
    [onChange]
  );
  return <Time value={formatValue(value)} onChange={handleChange} {...other} />;
}
