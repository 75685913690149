import * as React from 'react';
import { makeStyles, IconButton, Grid } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import * as LayoutStore from './store';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleMenu from './AccountCircleMenu';
import BrandLogoImage from '../../images/full_logo.png';
import { useAppBarHeightRef } from '../../hooks';
import { isAdmin } from '../../buildTarget';

interface ComponentProps {
  auth?: boolean;
}

const useStyles = makeStyles(({ breakpoints, spacing, palette, zIndex }: any) =>
  createStyles({
    appBar: {
      zIndex: zIndex.drawer + 1
    },
    toolBar: {
      background: palette.common.white,
      color: palette.primary.main
    },
    menuButton: {
      marginRight: spacing(2),
      [breakpoints.up('md')]: {
        display: 'none'
      }
    },
    logo: {
      height: 44,
      [breakpoints.down('xs')]: {
        height: 36
        //display: 'none',
      }
    }
  } as any)
);

export default function ApplicationBar({ auth }: ComponentProps) {
  const classes = useStyles();

  const [, setLeftDrawerOpen] = LayoutStore.useLeftDrawer();
  const handleOpenLeftDrawer = React.useCallback(() => setLeftDrawerOpen(true), [setLeftDrawerOpen]);

  return (
    <AppBar ref={useAppBarHeightRef()} position='fixed' color='default' className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <IconButton color='inherit' aria-label='open drawer' edge='start' onClick={handleOpenLeftDrawer} className={classes.menuButton}>
          <MenuIcon />
        </IconButton>
        <img className={classes.logo} src={BrandLogoImage} alt='logo' />
        {auth && !isAdmin && (
          <Grid container justify='flex-end'>
            <AccountCircleMenu />
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  );
}
