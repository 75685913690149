import merge from 'deepmerge';
import Decimal from 'decimal.js-light';

function getActiveMinutes(frequency, active) {
  return frequency === 'ECO' ? 20 : frequency === 'REGULAR' ? 40 : frequency === 'HEAVY' ? 40 : active;
}

function getPausedMinutes(frequency, paused) {
  return frequency === 'ECO' ? 40 : frequency === 'REGULAR' ? 40 : frequency === 'HEAVY' ? 20 : paused;
}

export const mapValuesOut = (config = { schedule: { cleanings: [] } }, values) => {
  const newConfigOut = merge(config, {
    schedule: {
      active_duration_mins: getActiveMinutes(values.frequency, values.active_duration_mins),
      paused_duration_mins: getPausedMinutes(values.frequency, values.paused_duration_mins)
    }
  });

  const cleanings = [
    {
      enabled: values.enabled1,
      start: values.start1,
      end: values.end1
    },
    {
      enabled: values.enabled2,
      start: values.start2,
      end: values.end2
    }
  ];

  newConfigOut.schedule['cleanings'] = cleanings; // Replace any existing cleaning settings with the new settings
  console.log({ newConfigOut, config, values });
  return newConfigOut;
};

export const getFrequency = (active_duration_mins = 0, paused_duration_mins = 0) => {
  if (new Decimal(active_duration_mins).equals(20) && new Decimal(paused_duration_mins).equals(40)) return 'ECO';
  else if (new Decimal(active_duration_mins).equals(40) && new Decimal(paused_duration_mins).equals(40)) {
    return 'REGULAR';
  } else if (new Decimal(active_duration_mins).equals(40) && new Decimal(paused_duration_mins).equals(20)) {
    return 'HEAVY';
  } else return 'CUSTOM';
};

export const mapValuesIn = message => {
  const schedule = message?.schedule;
  const cleanings = (message?.schedule?.cleanings ?? [{}, {}]).filter(c => !c.temporary); // Remove any temporary entries
  const cleaning1 = cleanings[0]; // Use the first non temporary entry for cleaning1
  const cleaning2 = cleanings[1]; // Use the first non temporary entry for cleaning2
  return {
    active_duration_mins: schedule?.active_duration_mins,
    paused_duration_mins: schedule?.paused_duration_mins,
    frequency: getFrequency(schedule?.active_duration_mins, schedule?.paused_duration_mins),
    enabled1: cleaning1?.enabled,
    start1: cleaning1?.start,
    end1: cleaning1?.end,
    enabled2: cleaning2?.enabled,
    start2: cleaning2?.start,
    end2: cleaning2?.end
  };
};
