import * as React from 'react';
import * as LayoutStore from './store';
import { createStyles } from '@material-ui/core';
import NavigationTreeView from './NavigationTreeView';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints, mixins, layout }: any) =>
  createStyles({
    drawer: {
      [breakpoints.up('sm')]: {
        width: layout.leftDrawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: ({ height }: any) => ({
      width: layout.leftDrawerWidth,
      height: height - layout.footerheight,
      [breakpoints.down('sm')]: {
        height: '100%',
      },
    }),
    drawerPaperTemporary: {
      width: layout.leftDrawerWidth,
    },
    toolbar: {
      ...mixins.toolbar,
    },
  })
);

const RegularDrawer = () => (
  <div style={{ padding: 8 }}>
    <NavigationTreeView />
  </div>
);

const LeftDrawer = () => {
  const height = LayoutStore.useHeight();
  const classes = useStyles({ height });
  const [leftDrawer, setLeftDrawer] = LayoutStore.useLeftDrawer();
  const handleLeftDrawerClose = React.useCallback(() => setLeftDrawer(false), [setLeftDrawer]);

  return (
    <nav className={classes.drawer} aria-label='navigation drawer'>
      <Hidden mdUp implementation='css'>
        <Drawer
          variant='temporary'
          open={leftDrawer}
          onClose={handleLeftDrawerClose}
          classes={{
            paper: classes.drawerPaperTemporary,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <RegularDrawer />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation='css'>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant='permanent'
          open
        >
          <div className={classes.toolbar} />
          <RegularDrawer />
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default LeftDrawer;
