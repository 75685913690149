import * as React from 'react';
import { makeStyles, createStyles, Grid, Button } from '@material-ui/core';
import Start from '../../../images/start.svg';
import Stop from '../../../images/stop.svg';
import { offlineOpacity } from '../../../constants';

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      width: 64,
      height: 64
    },
    actionButton: {
      width: 128,
      height: 128,
      color: 'white',
      background: '#489fdf',
      '&:hover': {
        color: 'white',
        background: '#489fdf'
      },
      '&:disabled': {
        opacity: offlineOpacity
      }
    }
  })
);

const ActionButton = ({ image, label, onClick, disabled }) => {
  const classes = useStyles();
  return (
    <Button className={classes.actionButton} variant='outlined' onClick={onClick} disabled={disabled}>
      <Grid container>
        <Grid item xs={12}>
          <img src={image} alt={label} className={classes.image} />
        </Grid>
        <Grid item xs={12}>
          {label}
        </Grid>
      </Grid>
    </Button>
  );
};

export default function Buttons({ handleStartStop, disabled }) {
  return (
    <Grid container justify='center' spacing={4} style={{ marginTop: 16, marginBottom: 64 }}>
      {[
        {
          label: 'Start',
          image: Start,
          onClick: handleStartStop('start')
        },
        {
          label: 'Stop',
          image: Stop,
          onClick: handleStartStop('stop')
        }
        //{ label: 'Home?', image: Home, onClick: () => { alert("To Be Implemented: Home"); } },
        //{ label: 'Power?', image: Power, onClick: () => { alert("To Be Implemented: Execute Power"); } }
      ].map(({ label, image, onClick }) => (
        <Grid key={label} item xs={6} style={{ textAlign: 'center' }}>
          <ActionButton label={label} image={image} onClick={onClick} disabled={disabled} />
        </Grid>
      ))}
    </Grid>
  );
}
