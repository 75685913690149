import * as React from 'react';
import axios from './config';
import { isEmpty, isDefined } from '../../helpers';
import { useAuth, useLogout } from '../layout/store';
import { Method } from 'axios';

async function processRequest(method = 'post' as Method, url, data = {}, token = undefined) {
  console.log({
    method,
    url,
    data,
    token
  });
  const headers = !isDefined(token) || isEmpty(token) ? {} : { Authorization: `Bearer ${token}` };
  return await axios({ method, url, data, headers });
}

export default function useRequest({ url, setState = undefined, onSuccess = undefined, onError = undefined, method = 'post' as any }) {
  const [error, setError] = React.useState();
  const { access } = useAuth();
  const handleLogout = useLogout();

  const handleRequest = React.useCallback(
    (values = {}, OnSuccess = undefined, OnError = undefined) => {
      setState && setState(prev => ({ ...prev, success: false, disabled: true, submitting: true, response: undefined }));
      async function submitData() {
        setError(undefined); // Reset any previous errors
        try {
          const response = await processRequest(method, url, values, access);
          console.log('Successful request.');
          console.log({ response, values });
          setState && setState(prev => ({ ...prev, success: true, disabled: false, submitting: false, errors: {}, response }));
          onSuccess && onSuccess(response);
          OnSuccess && OnSuccess(response);
        } catch (error) {
          const { response } = error;
          const { error_text = 'Unknown error' } = response?.data ?? {};
          console.error({ error_text, error, values });
          setError(error_text); // Set any new errors
          setState && setState(prev => ({ ...prev, success: false, disabled: false, submitting: false, errors: {}, response }));
          onError && onError(response);
          OnError && OnError(response);
          if (error_text.includes('exp claim is invalid')) {
            handleLogout();
          }
        }
      }
      submitData();
    },
    [access, url, method, setState, setError, onSuccess, onError, handleLogout]
  );

  return { handleRequest, error, setError };
}
