import * as React from 'react';
import { makeStyles, Grid, Paper, Typography, Divider, TextField, CircularProgress } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import BrandLogoImage from '../../images/full_logo.png';
import { TermsAndConditions } from '../../constants';
import Check from '../application/DialogField/Check';
import { bool, isEmpty, validateEmail } from '../../helpers';
import useRequest from '../axios/useRequest';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(({ palette, spacing, mixins }: any) =>
    createStyles({
        root: {
            background: palette.common.white,
            color: palette.primary.main,
            height: '100vh',
        },
        paper: {
            marginTop: 32,
            width: 300,
        },
        disclaimer: {
            marginTop: 32,
            maxWidth: 650,
        },
        panelarea: {
            background: '#F5F5F5',
            padding: 16,
        },
        button: {
            marginTop: 8,
        },
        toolbar: mixins.toolbar,
        wrapper: {
            width: 224
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -8,
            marginLeft: -12,
        },
        message: {
            color: 'green'
        },
    })
);

const validate = (values) => {
    const newErrors = {};

    ['email', 'password', 'confirmPassword', 'name'].forEach(k => {
        if (isEmpty(values[k])) {
            newErrors[k] = 'Required';
        }
    })

    if (!validateEmail(values['email'])) {
        newErrors['email'] = 'Invalid email format'
    }

    if (values['password'] !== values['confirmPassword']) {
        newErrors['password'] = 'Password does not match';
        newErrors['confirmPassword'] = 'Password does not match';
    }

    return newErrors;
}

export default function CreateAccount({ email: Email, success: Success, error: Error, onBack, onSuccess, method = 'put' }) {
    const BannerMsg = "";
    const classes = useStyles();
    const noAutoComplete = true;
    var buttonRef = React.useRef(null);
    const dispatch = useDispatch();

    const [values, setValues] = React.useState({
        name: '',
        email: isEmpty(Email) ? '' : Email,
        password: '',
        confirmPassword: '',
        agree: false,
        termsConditions: TermsAndConditions,
        step: 0
    });

    const [{ disabled }, setState] = React.useState({ disabled: false });

    const handleBack = React.useCallback(() => {
        dispatch(push('')); // This clears any query params
        setValues(prev => ({ ...prev, step: 0 }));
    }, [dispatch, setValues]);

    const { handleRequest, error } = useRequest({ url: '/v1/register?debug=true', method, setState, onSuccess });

    const [errors, setErrors] = React.useState({});
    const handleSubmit = React.useCallback(values => () => {
        dispatch(push('')); // This clears any query params
        const { name, email, password, confirmPassword } = values;
        console.log({ name, email, password })
        const newErrors = validate({ email, password, confirmPassword, name });
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            handleRequest({ email, password, name });
        } else {
            handleBack();
        }
    }, [dispatch, handleRequest, handleBack]);

    const handleChange = (name: string) => (event: any) => {
        setValues({ ...values, [name]: event.target.value });
        setErrors({});
    }

    const { name, email, password, confirmPassword } = values;

    const handleNext = React.useCallback(() => {
        dispatch(push('')); // This clears any query params
        const newErrors = validate({ email, password, confirmPassword, name });
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            setValues(prev => ({ ...prev, step: 1 }));
        }
    }, [dispatch, setValues, setErrors, email, password, confirmPassword, name])


    // Per https://stackoverflow.com/questions/50604671/programmatically-disabling-chrome-auto-fill
    // The only way to prevent auto fill is autocomplete=newpassword
    // Note this only prevents the browser from auto populating the fields, it does not prevent chromes new password manager
    return (
        <div
            className={classes.root}
            onKeyUp={(e: any) => {
                if (e.keyCode === 13) { // Enter key                    
                    buttonRef.current && buttonRef.current.click();
                }
            }}
        >
            <div className={classes.toolbar} />
            <form autoComplete='off'>
                <Grid container direction='column' justify='center' alignItems='center'>
                    <Grid item>
                        <Typography align='center' className={classes.disclaimer}>
                            {BannerMsg}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Typography align='center' className={classes.panelarea}>
                                <img src={BrandLogoImage} alt='logo' style={{ height: 44 }} />
                            </Typography>
                            <Divider />
                            <Grid container spacing={1} direction='column' justify='center' alignItems='center' style={{ padding: 16 }}>
                                {values.step === 0 ? <>
                                    <Grid item >
                                        <TextField
                                            autoComplete={noAutoComplete ? 'new-password' : undefined}
                                            id='name'
                                            label='Name'
                                            disabled={disabled}
                                            value={name}
                                            onChange={handleChange('name')}
                                            margin='dense'
                                            variant='outlined'
                                            error={isEmpty(errors['name']) ? false : true}
                                            helperText={errors['name']}
                                            fullWidth
                                            autoFocus
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <TextField
                                            autoComplete={noAutoComplete ? 'new-password' : undefined}
                                            id='email'
                                            label='Email Address'
                                            disabled={disabled}
                                            value={email}
                                            onChange={handleChange('email')}
                                            margin='dense'
                                            variant='outlined'
                                            error={isEmpty(errors['email']) ? false : true}
                                            helperText={errors['email']}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <TextField
                                            autoComplete={noAutoComplete ? 'new-password' : undefined}
                                            id='password'
                                            label='Password'
                                            disabled={disabled}
                                            type='password'
                                            value={password}
                                            onChange={handleChange('password')}
                                            margin='dense'
                                            variant='outlined'
                                            error={isEmpty(errors['password']) ? false : true}
                                            helperText={errors['password']}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <TextField
                                            autoComplete={noAutoComplete ? 'new-password' : undefined}
                                            id='confirmPassword'
                                            label='Confirm Password'
                                            disabled={disabled}
                                            type='password'
                                            value={confirmPassword}
                                            onChange={handleChange('confirmPassword')}
                                            margin='dense'
                                            variant='outlined'
                                            error={isEmpty(errors['confirmPassword']) ? false : true}
                                            helperText={errors['confirmPassword']}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </>
                                    :
                                    <>
                                        <Grid item >
                                            <TextField
                                                id='termsConditions'
                                                label='Terms and Conditions'
                                                style={{ width: 220 }}
                                                value={values.termsConditions}
                                                margin='dense'
                                                variant='outlined'
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                multiline={true}
                                                rows={10}
                                            />
                                        </Grid>
                                        <Grid item >
                                            <Check
                                                id='agree'
                                                width={220}
                                                label='I agree to the Terms & Conditions'
                                                value={values.agree}
                                                margin='dense'
                                                variant='outlined'
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={handleChange('agree')}
                                            />
                                        </Grid>
                                    </>
                                }

                                <Grid item>
                                    <div className={classes.wrapper}>
                                        <Button
                                            ref={buttonRef}
                                            fullWidth={true}
                                            disabled={disabled || (values.step === 1 && bool(values.agree) === false ? true : false)}
                                            color='primary'
                                            variant='contained'
                                            className={classes.button}
                                            onClick={values.step === 0 ? handleNext : handleSubmit(values)}
                                        >
                                            {values.step === 0 ? 'Next' : 'Create Account'}
                                        </Button>
                                        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className={classes.wrapper}>
                                        <Button
                                            fullWidth={true}
                                            disabled={disabled}
                                            color='secondary'
                                            variant='contained'
                                            className={classes.button}
                                            onClick={values.step === 1 ? handleBack : onBack}
                                        >
                                            {'< Back'}
                                        </Button>
                                        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                </Grid>

                            </Grid>
                            <Divider />
                            {Success && <Typography align='center' className={classes.message}>
                                {Success && Success}
                            </Typography>}
                            <Typography color='error' align='center' className={classes.panelarea}>
                                {Error ? `${Error}${error ? `. ${error}` : ''}` : error && error}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </form>
        </div >
    );
}
