import * as React from 'react';
import Slider from './Slider';

const marks = [
    {
        value: 0,
        label: 'ECO',
    },
    {
        value: 50,
        label: 'MEDIUM',
    },
    {
        value: 100,
        label: 'HIGH',
    },
];

const Speed = ({ getFieldState = undefined, ...props }) => <Slider marks={marks} step={50} {...props} />;
export default Speed