import * as React from 'react';
import { makeStyles, createStyles, Typography, Container, Paper, Box } from '@material-ui/core';
import { useWidth, useBotName, useChannelIn } from '../../layout/store';
import { useSnackBar } from '../../application/SnackBar/actions';
import { EditDialogButton } from '../../application/GenericDialog/DialogButton';
import * as BotNameDialog from '../../application/GenericDialog/BotName/BotName';
import useTelemetry from './useTelemetry';
import CleaningStatus from './CleaningStatus';
import Vitals from './Vitals';
import Buttons from './Buttons';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 300
    },
    paper: {
      padding: 8
    },
    productName: {
      fontWeight: 'bold',
      marginTop: 8
    }
  })
);

export default function Overview() {
  const classes = useStyles({});
  const width = useWidth();

  const { subscribed, handlePublish } = useChannelIn();
  const [, setSnackBar] = useSnackBar();

  const handleStartStop = React.useCallback(
    type => () => {
      if (subscribed) {
        handlePublish(
          JSON.stringify(
            {
              type
            },
            null,
            0
          )
        );
        setSnackBar({ open: true, variant: 'success', message: `Sent ${type} command` });
      } else {
        setSnackBar({ open: true, variant: 'error', message: 'Error sending command' });
      }
    },
    [subscribed, handlePublish, setSnackBar]
  );

  const telemData = useTelemetry();
  const botName = useBotName();

  const Content = (
    <>
      <Typography variant='h5' color='secondary' align='center' className={classes.productName}>
        {botName} {<EditDialogButton Module={BotNameDialog} mount={true} variant='smalliconbutton' />}
      </Typography>
      <Box mt={2}>
        <CleaningStatus telemData={telemData} />
      </Box>
      <Vitals telemData={telemData} />
      <Buttons handleStartStop={handleStartStop} disabled={telemData?.offline} />
    </>
  );
  return (
    <Container maxWidth='sm' className={classes.root}>
      {width < 400 ? Content : <Paper className={classes.paper}>{Content}</Paper>}
    </Container>
  );
}
