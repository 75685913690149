import * as React from 'react';
import { AppState } from './store';
import Decimal from 'decimal.js-light';
import { Address4, Address6 } from 'ip-address';
import moment from 'moment';
import { useTheme } from '@material-ui/core';
import { ThemeLayout, theme } from './constants';
import packageJson from '../package.json';
import buildTarget from './buildTarget';

const semver = require('semver');

export function hostAddress(append?) {
  return (
    window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + (append !== undefined ? append : '')
  );
}

export const debugSettings = {
  debugoutput: false,
  logviewport: false,
  logrender: true,
  logmapping: false,
  logreducer: false,
  logselectors: false,
  logform: true,
  logformupdates: true,
  debugfieldonhover: false
};

export function printHeader() {
  console.log(
    `%c
    
███████╗██╗  ██╗██╗███╗   ███╗      ██████╗ ███████╗██╗   ██╗██╗██╗     
██╔════╝██║ ██╔╝██║████╗ ████║      ██╔══██╗██╔════╝██║   ██║██║██║     
███████╗█████╔╝ ██║██╔████╔██║█████╗██║  ██║█████╗  ██║   ██║██║██║     
╚════██║██╔═██╗ ██║██║╚██╔╝██║╚════╝██║  ██║██╔══╝  ╚██╗ ██╔╝██║██║     
███████║██║  ██╗██║██║ ╚═╝ ██║      ██████╔╝███████╗ ╚████╔╝ ██║███████╗
╚══════╝╚═╝  ╚═╝╚═╝╚═╝     ╚═╝      ╚═════╝ ╚══════╝  ╚═══╝  ╚═╝╚══════╝                                                                       
Host: ${hostAddress()}
Build Target: ${buildTarget.name}, Environment: ${process.env.NODE_ENV}
Version: ${packageJson.version}                         
`,
    'font-family:monospace;color:' + theme.palette.primary.main + ';font-size:12px;'
  );
}

export function getViewPortHeight(state: AppState) {
  return state.layout.height;
}

export function getLayoutHeight(state: AppState) {
  return getViewPortHeight(state);
}

/**
 * Hack Fix for notifying the tab component that it needs to adjust it's indicator
 * Call this whenever you need to force a refresh of the tab indicator(s)
 * @export
 */
export function triggerResize() {
  window.dispatchEvent(new CustomEvent('resize')); //Makes the indicator start animating
  setTimeout(() => window.dispatchEvent(new CustomEvent('resize')), 200); //Ensures the animation resized correctly after the drawer finishes opening/closing
}

//This merges default props with ownprops, ensuring that null or undefined ownprop values do not override the default props
export function mergeProps(defaultProps, ownProp): any {
  var mergedProps = {};
  Object.keys(defaultProps).forEach(k => {
    mergedProps[k] = ownProp[k] !== undefined && ownProp[k] != null ? ownProp[k] : defaultProps[k];
  });
  return mergedProps;
}

export function debugoutput(value) {
  if (!debugSettings || !debugSettings.debugoutput) return; // eslint-disable-line

  var d = new Date();

  if (value.startsWith('Render') && debugSettings.logrender) console.log(d.getTime() + ': ' + value);
  else if (value.startsWith('SignalR') && debugSettings.logrender) console.log(d.getTime() + ': ' + value);
  else if (value.startsWith('Reducer') && debugSettings.logreducer) console.log(d.getTime() + ': ' + value);
  else if (value.startsWith('Selector') && debugSettings.logselectors) console.log(d.getTime() + ': ' + value);
  else if (value.startsWith('Mapping') && debugSettings.logmapping) console.log(d.getTime() + ': ' + value);
  else if (value.startsWith('Viewport') && debugSettings.logviewport) console.log(d.getTime() + ': ' + value);
  else if (value.startsWith('Form') && debugSettings.logform) console.log(d.getTime() + ': ' + value);
}

export function debugoutputdata(value) {
  if (!debugSettings.debugoutput) return;
  if (debugSettings.logformupdates) console.log(value);
}

export function spread(final, current) {
  return { ...final, ...current };
}

// React helpers
export function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

///////////////////////////////
// Currency Helper Functions //
///////////////////////////////

export function dollars(cents: number) {
  return (cents / 100).toFixed(2);
}

export function cents(dollars: string): number {
  const val = Math.round(100 * parseFloat(dollars.replace(/[$,]/g, '')));
  return isNaN(val) ? 0 : val;
}

//Only convert to money if there is a value to convert, this prevents uncessary external state changes for empty values to $0.00 when used with an input change
export function formatMoney(value): string {
  return value !== null && value !== undefined && value !== '' ? toMoney(value, true) : value;
}

//Only convert to cents if there is a value to convert, preventing uncessary convertions from empty to 0
export function formatCents(value): string {
  return value !== null && value !== undefined && value !== '' ? cents(value).toString() : value;
}

// NOTE: does not work properly with values longer than
// single-digit trillions
//Converts whole number cents to money
export function toMoney(cents: number | string, excludeSymbol?): string {
  if (!cents) cents = 0;
  else if (typeof cents == 'string') cents = parseInt(cents);

  var isnegative = cents < 0 ? true : false;

  if (isnegative) cents = cents * -1;

  //cents = parseFloat(cents) ? cents : 0.0;

  // place to two decimals then split off dollars and cents
  var components = (cents / 100).toFixed(2).toString().split('.');
  var decimal = components[1];
  var dollars = components[0] || '';
  var mod, remainder;
  var stringReverse = function (str) {
    return str.split('').reverse().join('');
  };

  // do we need to worry about commas?
  if (dollars.length > 3) {
    // since the commas are counted 3 characters from the
    // *right* we need to do some reversing
    dollars = stringReverse(dollars);

    // the match method used leaves off digits mod 3, so
    // we need to calculate what those omissions will be
    // eslint-disable-next-line
    remainder = (mod = dollars.length % 3) ? stringReverse(dollars.slice(mod * -1)) + ',' : '';

    // split every three characters, add commas, then add
    // omissions introduced by 'match' remainder back on
    dollars = remainder + stringReverse(dollars.match(/.../g).join(','));
  }

  // put it all together
  return [!excludeSymbol ? (isnegative ? '-$' : '$') : '', [dollars, decimal].join('.')].join('');
}

//compares the sorted object values of two arrays.  Returns true if equal.  False if not.
export function compareSortedArrayValues(a, b) {
  if (a.length !== b.length) return false;
  for (var i = 0; i < b.length; i++) {
    if (a[i].compare) {
      //To test values in nested arrays
      if (!a[i].compare(b[i])) return false;
    } else if (a[i] !== b[i]) return false;
  }
  return true;
}

//This can be used with an array filter method to return unique values. Example: array.filter( onlyUnique )
export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

//sorts an array of objects, with the display function returning the values to sort by
export function sortdisplayobjects(array, display) {
  array.sort((a, b) => {
    if (display(a) < display(b)) return -1;
    if (display(a) > display(b)) return 1;
    return 0;
  });
  return array;
}

export function sortascending(a, b) {
  if (a < b) return 1;
  if (a > b) return -1;
  return 0;
}
export function sortdescending(a, b) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

////////////////////////////////
// Time/Date Helper Functions //
////////////////////////////////

export const dateFromDotNet = (dotnetdate: string) => moment(dotnetdate, 'YYYY-MM-DDTHH:mm:ssZ').toDate();

export const withinDateRange = (startdate: string, enddate: string) => {
  const today = Date.parse(yyyymmdd(new Date()));
  if (
    (startdate === null || startdate === undefined || startdate === '' || today >= Date.parse(startdate)) &&
    (enddate === null || enddate === undefined || enddate === '' || today <= Date.parse(enddate))
  ) {
    return true;
  }
  return false;
};

export const withinTimeRange = (starttime: string, endtime: string) => {
  const time = getHoursMinutes(new Date());
  if (
    (starttime === null || starttime === undefined || starttime === '' || time >= starttime) &&
    (endtime === null || endtime === undefined || endtime === '' || time <= endtime)
  ) {
    return true;
  }
  return false;
};

export function dayOfWeek(dayIndex) {
  return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][dayIndex];
}
export function dayAbbrOfWeek(dayIndex) {
  return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][dayIndex];
}
export function monthOfYear(monthIndex) {
  return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][monthIndex];
}
export function monthAbbrOfYear(monthIndex) {
  return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][monthIndex];
}
export function nth(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export function mmddyyy_from_dotnet(date: string) {
  return getDateFromTimestamp(getLocalServerTimestamp(date));
}

export function getLocalDateFromDotNet(date: string) {
  return getDateFromTimestamp(getLocalServerTimestamp(date));
}

export function getLocalDayTimeFromDotNet(date: string) {
  return getDayTimeFromTimestamp(dateFromDotNet(date as any).getTime());
}

//Converts a dotnet date to local timestamp adjusted for local timezone
export function getLocalServerTimestamp(date: string) {
  return convertUtcTimestampToLocalTimestamp(dateFromDotNet(date as any).getTime());
}

//Converts a UTC timestamp to Localtimestamp
export function convertUtcTimestampToLocalTimestamp(timestamp: number) {
  var d = new Date();
  var minutesOffset = d.getTimezoneOffset();
  var msOffset = minutesOffset * 60 * 1000;
  return timestamp - msOffset;
}

export function convertLocalTimestamptoUtcTimestamp(timestamp: number) {
  var d = new Date();
  var minutesOffset = d.getTimezoneOffset();
  var msOffset = minutesOffset * 60 * 1000;
  return timestamp + msOffset;
}

// Format time as HHMM or HMM
export const utcTimeToLocal = (time: string | number) => {
  const valStr = time.toString().padStart(4, '0'); // Ensure 4 indexes
  const hours = Number(valStr.substr(0, 2)); // Extract hours
  const minutes = Number(valStr.substr(2, 4)); // Extract minutes

  var d = new Date();
  d.setHours(hours, minutes);
  const localTimestamp = convertUtcTimestampToLocalTimestamp(d.getTime());

  var d2 = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d2.setUTCMilliseconds(localTimestamp);
  const localHours = d2.getHours().toString();
  const localMinutes = d2.getMinutes().toString();
  return `${localHours.padStart(2, '0')}${localMinutes.padStart(2, '0')}`; //Ensure minutes utilize 2 indexes
};

// Format time as HHMM or HMM
export const localTimeToUtc = (time: string | number) => {
  const valStr = time.toString().padStart(4, '0'); // Ensure 4 indexes
  const hours = Number(valStr.substr(0, 2)); // Extract hours
  const minutes = Number(valStr.substr(2, 4)); // Extract minutes

  var d = new Date();
  d.setHours(hours, minutes);
  const utcTimestamp = convertLocalTimestamptoUtcTimestamp(d.getTime());

  var d2 = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d2.setUTCMilliseconds(utcTimestamp);
  const localHours = d2.getHours().toString();
  const localMinutes = d2.getMinutes().toString();
  return `${localHours.padStart(2, '0')}${localMinutes.padStart(2, '0')}`;
};

export function getDateTimeFromTimestamp(timestamp: number) {
  var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d.setUTCMilliseconds(timestamp); //utc time
  var h = d.getHours();
  var m = addZero(d.getMinutes());
  var isPM = h > 12 ? true : false;
  h = isPM ? h - 12 : h === 0 ? 12 : h; //If PM, subtract 12.  If we are 0 or midnight, then set to 12, otherwise use the normal hour index
  return {
    date: timestamp && getDateFromTimestamp(timestamp),
    time: timestamp && `${h}:${m} ${isPM ? 'PM' : 'AM'}`
  };
}

export function getDayTimeFromTimestamp(timestamp: number, includeSeconds = false) {
  var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d.setUTCMilliseconds(timestamp); //utc time
  var day = dayAbbrOfWeek(d.getDay());
  var year = d.getFullYear();
  var h = d.getHours();
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());
  var isPM = h > 12 ? true : false;
  h = isPM ? h - 12 : h === 0 ? 12 : h; //If PM, subtract 12.  If we are 0 or midnight, then set to 12, otherwise use the normal hour index
  var month = monthAbbrOfYear(d.getMonth());

  return includeSeconds ? `${day} ${month} ${year} ${h}:${m}:${s} ${isPM ? 'PM' : 'AM'}` : `${day} ${month} ${year} ${h}:${m} ${isPM ? 'PM' : 'AM'}`;
}

export function getDayTime(date?: Date) {
  var d = date || new Date(); //Default to current time if we don't specify a time
  var day = dayAbbrOfWeek(d.getDay());
  var year = d.getFullYear();
  var h = d.getHours();
  var m = addZero(d.getMinutes());
  var isPM = h > 12 ? true : false;
  h = isPM ? h - 12 : h === 0 ? 12 : h; //If PM, subtract 12.  If we are 0 or midnight, then set to 12, otherwise use the normal hour index
  var month = monthAbbrOfYear(d.getMonth());

  return `${day} ${month} ${year} ${h}:${m} ${isPM ? 'PM' : 'AM'}`;
}

export function getCurrentDayTime() {
  var now = new Date();
  var day = dayAbbrOfWeek(now.getDay());
  var date = now.getDate() + nth(now.getDate());
  var h = now.getHours();
  var m = addZero(now.getMinutes());
  var isPM = h > 12 ? true : false;
  h = isPM ? h - 12 : h === 0 ? 12 : h; //If PM, subtract 12.  If we are 0 or midnight, then set to 12, otherwise use the normal hour index
  var month = monthAbbrOfYear(now.getMonth());

  return `${day} ${month} ${date} ${h}:${m} ${isPM ? 'PM' : 'AM'}`;
}
//returns milliseconds from Jan 1 1970
export function getTime() {
  return Date.now();
}
export function getIsoDateString(timestamp?: number) {
  var d = new Date(0);
  d.setUTCMilliseconds(timestamp || getTime());
  return d.toISOString();
}

export function getDateFromTimestamp(milliseconds): string {
  //UTC timestamp is milliseconds since Jan 1 1970
  var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  //utc time
  d.setUTCMilliseconds(milliseconds);
  return mmddyyyy(d);
}

export function getTimeFromTimestamp(milliseconds): string {
  //UTC timestamp is milliseconds since Jan 1 1970
  var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  //utc time
  d.setUTCMilliseconds(milliseconds);
  return getHoursMinutes(d);
}

function addZero(i) {
  if (i < 10) {
    i = '0' + i;
  }
  return i;
}

export function getHoursMinutes(date) {
  var h = addZero(date.getHours());
  var m = addZero(date.getMinutes());
  return h + ':' + m;
}

export function yyyymmdd(date?: Date) {
  date = date ? date : new Date(); //If we don't pass a date in, then create it

  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();

  return [date.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-');
}

export function mmddyyyy(date) {
  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();

  return [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('/');
}

export function getDateForInput(date) {
  //should be in this format: 2019-01-02
  return yyyymmdd(date);
}

////////////////////////////////
//   React Helper Functions   //
////////////////////////////////
export function isClassComponent(component) {
  return typeof component === 'function' && !!component.prototype.isReactComponent ? true : false;
}

export function isFunctionComponent(component) {
  return typeof component === 'function' && String(component).includes('return React.createElement') ? true : false;
}

export function isReactComponent(component) {
  return isClassComponent(component) || isFunctionComponent(component) ? true : false;
}

export function isElement(element) {
  return React.isValidElement(element);
}

export function isDOMTypeElement(element) {
  return isElement(element) && typeof element.type === 'string';
}

export function isCompositeTypeElement(element) {
  return isElement(element) && typeof element.type === 'function';
}

//Reduce Helpers

export function concat(final, current) {
  return final.concat(current);
}

export function notundefined(t, c) {
  return t && c !== undefined;
}

export function anynotundefined(t, c) {
  return t || c !== undefined;
}

export function notnull(t, c) {
  return t && !c;
}

export function alltrue(t, c) {
  return t && (c === true ? true : false);
}

export function anytrue(t, c) {
  return t || c;
}

export function allfalse(t, c) {
  return t || c;
}

export function sum(total, amount) {
  return total + amount;
}
export function decimalsum(total: Decimal, amount: Decimal) {
  return total.add(amount);
}

//Validate helpers
export const validateIPv4Octet = (octet: any, includeWildCards?: boolean) =>
  (includeWildCards && octet === '*') || (!isNaN(octet) && parseInt(octet) >= 0 && parseInt(octet) <= 255);

export function validateIPv4(ip: string, includeWildCards?: boolean): boolean {
  const octets = ip.split('.');
  return octets.length === 4
    ? !octets.map((o: any) => !validateIPv4Octet(o, includeWildCards)).reduce(anytrue, false) // if any true then the ip is invalid
    : false;
}

export const isValidIPv4 = ip => new Address4(ip).isValid();
export const isValidIPv6 = ip => new Address6(ip).isValid();
export const isValidIP = ip => isValidIPv4(ip) || isValidIPv6(ip);

export const isLargerIP = (ip1, ip2) => {
  const ip1v4 = new Address4(ip1);
  const ip2v4 = new Address4(ip2);
  const ip1v6 = new Address6(ip1);
  const ip2v6 = new Address6(ip2);

  if (ip1v4.isValid() && ip2v4.isValid()) {
    return ip2v4.bigInteger() >= ip1v4.bigInteger();
  } else if (ip2v6.isValid() && ip2v6.isValid()) {
    return ip2v6.bigInteger() >= ip1v6.bigInteger();
  }
  return false;
};

export function isEmpty(str) {
  return !str || 0 === str.length;
}

export function newGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const setIfEmpty = value => (value === undefined || value === null ? '' : value);
export const setIfEmptyStr = (value, conditionalValue) => {
  return isEmpty(value) ? conditionalValue : value;
};
export const checkEmpty = value => value === undefined || value === null || value === '';
export const undefinedIfEmpty = value => (checkEmpty(value) ? undefined : value);
export const isDefined = value => value !== undefined && value !== null;
export const hasChanged = (current, initial) => JSON.stringify(current) !== JSON.stringify(initial);

export const createPdis = (models, ServerGUID?, other?) => {
  if (!Array.isArray(models)) {
    models = [models];
  }
  return models.map(Model => ({
    Model,
    Action: 'r',
    ServerGUID,
    ...other
  }));
};

export const getKey = ({ container, object, id }: any) => [container, object, id].filter(v => !checkEmpty(v)).join('.');

export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const uncapitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toLowerCase() + s.slice(1);
};

export function copyToLower(obj = {}) {
  var key,
    keys = Object.keys(obj);
  var n = keys.length;
  var newobj = {};
  while (n--) {
    key = keys[n];
    newobj[uncapitalize(key)] = obj[key];
  }
  return newobj;
}

export const getLabel = f => (f.label === null ? undefined : f.label !== undefined ? f.label : f.id);

export const useThemeLayout = (): ThemeLayout => (useTheme() as any).layout;

// Gets the unique identifer for a remote domain node
export const rdKey = rd => (rd && rd.DomainHost ? `${rd.DomainHost.Host}: ${rd.DomainHost.Port}` : '');

// Converts a string, number or boolean to boolean with default false
export const bool = x =>
  typeof x === 'boolean'
    ? x
    : typeof x === 'number'
    ? x === 1
      ? true
      : false
    : typeof x === 'string'
    ? x === '1'
      ? true
      : x.toLowerCase() === 'true'
      ? true
      : false
    : false;

export const ibool = x =>
  typeof x === 'boolean'
    ? x
    : typeof x === 'number'
    ? x === 1 || x === 2 // 2 is for the indeterminate checkbox value, which should imply true
      ? true
      : false
    : typeof x === 'string'
    ? x === '1' || x === '2' // 2 is for the indeterminate checkbox value, which should imply true
      ? true
      : x.toLowerCase() === 'true'
      ? true
      : false
    : false;

// If a function, then return the result of the function call with props passed in.  Else return the value
export const evalFunc = (value, props = undefined) => (typeof value === 'function' ? value(props) : value);

export const getAction = (type: string) =>
  type.toUpperCase() === 'ADD' ? 'c' : type.toUpperCase() === 'EDIT' ? 'u' : type.toUpperCase() === 'DELETE' ? 'd' : 'r';
export const emptyUndefined = value => (value === false || value === null ? undefined : value);

export const isError = value => (value === undefined || value === null || value === '' ? false : true);

export const copyDeleteKey = (key, obj) => deleteKey(key, { ...obj });

export const deleteKey = (key, obj) => {
  delete obj[key];
  return obj;
};

export const useModelVariant = pdi => (pdi ? (pdi.UserGUID ? 'Usr' : pdi.GroupGUID ? 'Grp' : 'Svr') : 'Svr'); // Extract variant from provided properties

export function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

export const isObject = obj => typeof obj === 'object';
export const isObjectWithKeys = obj => isObject(obj) && Object.keys(obj).length > 0;

export const replaceChar = (str, index, char) => str.substr(0, index) + char + str.substr(index + 1);

export function downloadFile(content, fileName, contentType) {
  var a = document.createElement('a');
  var file = new Blob([content], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
}

export function saveFile(blob, filename) {
  if (blob !== undefined) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  } else {
    console.error('Error saving file.  File is undefined');
  }
}

// Remove empty on existing object
export const removeEmpty = (obj = {}) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
    // recurse
    else if (obj[key] === null || obj[key] === '') {
      delete obj[key]; // delete
    }
  });
};

// Remove empty, return new object
export const removeEmptyNew = (obj = {}) => {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      newObj[key] = removeEmptyNew(obj[key]); // recurse
    } else if (obj[key] !== null && obj[key] !== '') {
      newObj[key] = obj[key]; // copy value
    }
  });
  return newObj;
};

export function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
  var mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
  var sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
  return hDisplay + mDisplay + sDisplay;
}

export function secondsToHM(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);

  var hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
  var mDisplay = m > 0 ? m + (m === 1 ? ' minute' : ' minutes') : '';
  return hDisplay + mDisplay;
}

export function secondsToM(d) {
  d = Number(d);
  var m = Math.floor((d % 3600) / 60);
  return m > 0 ? m + (m === 1 ? ' minute' : ' minutes') : '';
}

export function updateState(state, { payload, id }) {
  const data = evalFunc(payload, state[id]);
  var newState = { ...state };
  newState[id] = data;
  if (state && state[id]) {
    newState[id] = data;
  }
  return newState;
}

export const stringifyEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

// Returns minutes elapsed from time to current time
export function minutesFrom(time) {
  var current = new Date().getTime();
  var diff = (current - time) / 60000; //converts milliseconds to elapsed minutes
  return diff > 0 ? diff : 0;
}

export function minutesToTimeAgo(minutes) {
  var d = Math.floor(minutes / 1440);
  var h = Math.floor((minutes - d * 1440) / 60);
  var m = Math.floor(minutes % 60);
  var ret = '';
  if (d > 0) {
    ret = ret + d + 'd ';
  }
  if (h > 0 || d > 0) {
    ret = ret + h + 'h ';
  }
  if (m >= 0 || h > 0 || d > 0) {
    ret = ret + m + 'm ';
  }
  if (ret !== '') ret = ret + 'ago';
  return ret;
}

export const timeAgo = time => (time ? minutesToTimeAgo(minutesFrom(time)) : '');

export const canUpdateVersion = (version, newestVersion) => (!isEmpty(newestVersion) && !isEmpty(version) ? semver.gt(newestVersion, version) : false);

export const getFileName = file => {
  return (file?.name ?? 'unknown').split(' ').join('_'); // Get filename and remove any spaces
};

export function secondsToDurationString(sec, withVerb = false) {
  let n = Number(sec);
  const d = Math.floor(n / 3600 / 24);
  const h = Math.floor((n / 3600) % 24);
  const m = Math.floor((n % 3600) / 60);
  const s = Math.floor((n % 3600) % 60);

  const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : '';
  const hDisplay = h > 0 ? h + (d && m > 30 ? 1 : 0) + (h === 1 ? ' hour, ' : ' hours, ') : '';
  const mDisplay = m > 0 && !d ? m + (s > 30 ? 1 : 0) + (m === 1 ? ' minute, ' : ' minutes, ') : '';
  const sDisplay = s > 0 && !m && !h && !d ? s + (s === 1 ? ' second' : ' seconds') : '';
  const str = (dDisplay + hDisplay + mDisplay + sDisplay).replace(/\s*,\s*$/, '');
  const verb = withVerb ? (str.startsWith('1 ') ? 'is ' : 'are ') : '';
  return verb + str;
}

export function toDateTimeString(date: Date) {
  const [d] = secondsToTimeUnits((new Date().getTime() - date.getTime()) / 1000);
  var str = '';
  if (d > 0) {
    str += date.toLocaleDateString([], d > 6 ? { day: 'numeric', month: 'short' } : { weekday: 'long' }) + ' at ';
  }
  str += date.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' });
  return str;
}

export function secondsToTimeUnits(s: number) {
  var m = Math.trunc(s / 60);
  var h = Math.trunc(m / 60);
  var d = Math.trunc(h / 24);

  return [d, h, m, s];
}
