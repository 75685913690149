import { connect, MqttClient, IClientOptions } from 'mqtt';
import React from 'react';
import { newGuid } from '../../helpers';
import MqttContext from './Context';
import { Message, MessageStructure } from './types';

interface Props {
  brokerUrl?: string | object;
  opts?: IClientOptions | undefined;
  children: React.ReactNode;
}

export default function Connector({ children, brokerUrl, opts }: Props) {
  const [status, setStatus] = React.useState<string>('offline');
  const [mqtt, setMqtt] = React.useState<MqttClient>();
  const [messages, setMessages] = React.useState<Message<MessageStructure>[]>([]);

  const handleMessage = React.useCallback(
    (t, message: Message<{}>) => {
      let msg;
      try {
        msg = JSON.parse(message.toString());
      } catch (e) {
        msg = message.toString();
      }
      setMessages(state => [
        ...state,
        {
          message: msg,
          topic: t,
          id: newGuid()
        }
      ]);
    },
    [setMessages]
  );

  React.useEffect(() => {
    const mqttInstance = connect(brokerUrl, opts);
    setMqtt(mqttInstance);
    mqttInstance.on('connect', () => setStatus('connected'));
    mqttInstance.on('reconnect', () => setStatus('reconnecting'));
    mqttInstance.on('close', () => setStatus('closed'));
    mqttInstance.on('offline', () => setStatus('offline'));
    mqttInstance.on('message', handleMessage);
    return () => {
      mqttInstance.end();
    };
  }, [handleMessage, brokerUrl, opts]);

  const lastMessage = messages[messages.length - 1];

  return <MqttContext.Provider value={{ status, mqtt, messages, lastMessage }}>{children}</MqttContext.Provider>;
}
