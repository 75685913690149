import * as React from 'react';
import MQTTPattern from 'mqtt-pattern';
import MqttContext from './Context';
import { MqttContext as Context, Message } from './types';

export default function useSubscription<T>({ topic, type = undefined, onMessage = undefined, disabled = undefined }) {
  const { mqtt, status, messages, lastMessage } = React.useContext<Context<T>>(MqttContext);
  const [subscriptionStatus, setSubscriptionStatus] = React.useState('unsubscribed');

  const connected = disabled !== true && status === 'connected' ? true : false;
  const subscribed = connected && subscriptionStatus === 'subscribed';
  const ready = connected && subscriptionStatus === 'unsubscribed';

  React.useEffect(() => {
    if (subscribed && topic && lastMessage && onMessage && MQTTPattern.matches(topic, lastMessage.topic)) {
      onMessage && onMessage(lastMessage);
    }
  }, [subscribed, topic, lastMessage, onMessage]);

  const handlePublish = React.useCallback(
    message => {
      console.log('Publishing:');
      console.log({ topic, message, status, subscribed, ready, connected });
      mqtt?.publish(topic, message);
    },
    [mqtt, topic, status, subscribed, ready, connected]
  );

  React.useEffect(() => {
    var done = false;
    function subscribe() {
      try {
        mqtt?.subscribe(topic, function (err) {
          if (!err) {
            console.log('Subscribed: ' + topic);
            !done && setSubscriptionStatus('subscribed');
          } else {
            console.error('Error subscribing: ' + topic);
            !done && setSubscriptionStatus('error');
          }
        });
      } catch (error) {
        console.error('Error subscribing: ' + topic);
        console.error(error);
        !done && setSubscriptionStatus('error');
      }
    }
    ready && subscribe(); // Only subscribe when mqtt is connected and this instance is not already subscribed
    return () => {
      done = true; // Flag cleanup to prevent memory leak updates from setSubscritptionStatus being called in the async callbacks above
      if (subscribed) {
        try {
          mqtt?.unsubscribe(topic, function (err) {
            if (!err) {
              console.log('Unsubscribed: ' + topic);
            } else {
              console.error('Error unsubscribing: ' + topic);
            }
          });
        } catch (error) {
          console.error('Error unsubscribing: ' + topic);
          console.error(error);
        }
        setSubscriptionStatus('unsubscribed');
      }
    };
  }, [mqtt, ready, subscribed, topic, setSubscriptionStatus]);

  const msgs = messages.filter((msg: Message<any>) => MQTTPattern.matches(topic, msg.topic) && (type === undefined || msg?.message?.type === type));
  const lastMessageOnTopic = msgs[msgs.length - 1];

  return {
    msgs,
    mqtt,
    status,
    subscriptionStatus,
    subscribed,
    lastMessageOnTopic,
    topic,
    handlePublish
  };
}
