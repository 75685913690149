import React from 'react';
import Fields from './Fields';
import { Grid } from '@material-ui/core';
import OutlinedDiv from '../../general/OutlinedDiv/OutlinedDiv';

const FieldsTemplate = ({ Template, containerLabel, ...other }: any) => {
  var Content = Template ? (
    <Grid container alignItems='center' justify='center'>
      <Template {...other} />
    </Grid>
  ) : (
    <Grid container alignItems='center' spacing={1}>
      <Fields {...other} />
    </Grid>
  );
  return containerLabel !== undefined && containerLabel !== null ? (
    <OutlinedDiv margin={8} padding={24} label={containerLabel}>
      {Content}
    </OutlinedDiv>
  ) : (
    Content
  );
};

export default FieldsTemplate;
