import React from 'react';
import { useDialogState } from '../actions';
import GenericDialog from '../GenericDialog';
import { useHousehold, useLayout } from '../../../layout/store';
import { isEmpty } from '../../../../helpers';

export const title = 'Bot Name';
export const getDefaultBotName = (nickname, loading = false) => (loading ? '' : isEmpty(nickname) ? 'UNKNOWN BOT NAME' : nickname);

export default function BotNameDialog({ id = title, onClose, ...other }) {
  const [state, setState] = useDialogState(id);
  const { open, submitting } = state;
  const [layout, setLayout] = useLayout();

  const { household = {}, handleRequest, handleUpdate } = useHousehold({ id: layout.household?.id, state, setState });

  const products = household.products ?? [];
  const product = products.length > 0 && products[0];
  const nickname = getDefaultBotName(product?.nickname, submitting);
  const household_str = JSON.stringify(household);

  React.useEffect(() => {
    open && handleRequest({});
  }, [open, handleRequest]);

  const handleClose = React.useCallback(() => {
    setState(prev => ({ ...prev, open: false, submitting: false, errors: {} }));
  }, [setState]);

  const handleSuccess = React.useCallback(
    household => {
      setLayout({ household }); // Update the house hold information in the layout store so changes are visible to the user
      handleClose();
    },
    [setLayout, handleClose]
  );

  const handleEdit = React.useCallback(
    ({ nickname }) => {
      const household = JSON.parse(household_str);
      if (household.products && household.products.length > 0) {
        household.products[0].nickname = nickname; // Replace the nick name, but be sure to keep all other data the same so nothing gets changed that shouldn't
        handleUpdate(household, handleSuccess(household), () => alert('Error saving bot name'));
        onClose && onClose();
      } else {
        console.error('Error updating household: no products');
      }
    },
    [onClose, household_str, handleUpdate, handleSuccess]
  );

  const handleValidation = (values, dialogState) => {
    var errors = { ...dialogState.errors };
    return errors;
  };

  return (
    <GenericDialog
      id={id}
      title={title}
      onSubmit={handleEdit}
      onClose={onClose}
      validate={handleValidation}
      initialValues={{ nickname }}
      submitLabel='Save'
      fields={[
        {
          id: 'nickname',
          label: 'Bot Name'
        }
      ]}
      {...other}
    />
  );
}
