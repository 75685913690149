import * as React from 'react';
import { MenuItem, IconButton, Menu } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import * as LayoutStore from './store';
import * as ProfileDialog from '../application/GenericDialog/Profile/Profile'
import DialogButton from '../application/GenericDialog/DialogButton';

export default function AccountCircleMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenu = React.useCallback(event => setAnchorEl(event.currentTarget), [setAnchorEl]);
  const handleClose = React.useCallback(() => setAnchorEl(null), [setAnchorEl]);
  const handleLogout = LayoutStore.useLogout();

  return (
    <>
      <IconButton
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={handleMenu}
        color='inherit'
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <div><DialogButton variant='menuitem' label='My Profile' Module={ProfileDialog} onClose={handleClose} /></div>
        <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
      </Menu>
    </>
  );
}
